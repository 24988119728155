import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/airdrop.css'
import qrImg from "../../assets/images/qr/qr.png";
import LinkHref from "../../components/LinkHref/LinkHref";

function Airdrop() {
    const { user } = useTelegram();

    const handleClickToClicker = () => {
        console.log('click')
    }

    return (
            <div className={'wrapper'} style={{
                background: '#1C1F24',
                padding: 24,
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "column",
                paddingBottom: 90,
                justifyContent:'center',
                alignItems:'center',
                height: '100vh'
            }}>

                <h2 style={{color: 'white', fontSize:22, fontWeight: '600'}}>Play on your mobile</h2>

                <img src={qrImg} alt="qr" style={{width: 295, margin: '20px 0', borderRadius:8}}/>

                <LinkHref style={{color: 'white', fontSize:18, fontWeight: '600'}} to={'https://t.me/Catscoin_bot'} className={'airdrop-item'}>
                    @Catscoin_bot
                </LinkHref>

            </div>

    );
}

export default Airdrop;

