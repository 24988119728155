import {useState, useEffect, useRef} from 'react';
import {useTelegram} from "../Context/TelegramContext/index";
import {stringToBase64} from "../utils/format";

const useGetRewardPrices = () => {

    const DOMAIN = process.env.REACT_APP_DOMAIN;
    const { user, triggerVibration, webApp  } = useTelegram();

    const [dailyRewardDays, setDailyRewardDays] = useState(null);
    const [loadingDailyRewardDays, setLoadingDailyRewardDays] = useState(true);
    const [errorDailyRewardDays, setErrorDailyRewardDays] = useState(null);

    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchGetRewardPrices();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchGetRewardPrices()
            }
        }


    }, [webApp?.initData]);

    const fetchGetRewardPrices = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/rewards-list/`, {
            method: 'GET',
            headers: {
                "X-UserId": init_data
            }
        });

        const statusCode = response.status;

        if (response.ok) {
            const data = await response.json();
            if (statusCode === 200 || statusCode === 201) {
                setDailyRewardDays(data);
                setLoadingDailyRewardDays(false);
                setErrorDailyRewardDays(false);
            }
        } else {
            setErrorDailyRewardDays(true);
            setLoadingDailyRewardDays(false);

            if (statusCode === 403) {
                // navigate('/banned')
            } else if (statusCode === 401) {
                // navigate('/login')
            }
        }


    };


    return {dailyRewardDays,  loadingDailyRewardDays, errorDailyRewardDays, fetchGetRewardPrices };
};

export default useGetRewardPrices;
