import React, { useEffect, useState, useRef } from 'react';
import { useTelegram } from '../../../Context/TelegramContext';
import '../../../assets/css/login.css';
import Footer from "../../../components/Footer";
import banned from '../../../assets/images/banned.png';
import passport from '../../../assets/images/passport.png';
import download from '../../../assets/images/download.png';

import slide1 from '../../../assets/images/slide1.png';
import slide2 from '../../../assets/images/slide2.png';
import slide3 from '../../../assets/images/slide3.png';
import slide4 from '../../../assets/images/slide4.png';

import Header from "../../../components/Header";
import gift from "../../../assets/images/gift.png";
import {
    CopyIcon,
    GreenIcon,
    LineIcon,
    MonetaIcon,
    RefreshIcon,
    RightIcon,
    TgIcon,
    Transparenticon
} from "../../../components/icons";
import img1 from "../../../assets/images/img1.png";
import calendar from "../../../assets/images/calendar.png";
import { LngArrow, RuIcon } from "../../../components/LanguageIcons";
import { useNavigate } from 'react-router-dom';
import LanguageModal from "../../../components/LanguageModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';


function Login({ setIsLogin }) {
    const { user, triggerVibration } = useTelegram();
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const notify = (error) => toast.error(error, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        // transition: Bounce,
    });
    const [selectedLanguage, setSelectedLanguage] = useState('ru');
    const [isOpenLngModal, setIsOpenLngModal] = useState(false);

    const [catName, setCatName] = useState('');
    const [catAvatar, setCatAvatar] = useState(null);
    const [catAvatarPreview, setCatAvatarPreview] = useState(null);

    const [showSlider, setShowSlider] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0);


    //
    // useEffect(() => {
    //     console.log(user, 'user');
    //     const slider = localStorage.getItem('slider');
    //
    //     if (!slider) {
    //         setShowSlider(true);
    //     }
    //
    // }, [user]);



    const handleFileChange = (e) => {
        // console.log(e, 'file')
        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {
            if (file.size > 1024 * 1024) {
                // setErrorMessage('Размер файла не должен превышать 1 МБ.');
                setCatAvatar(null);
                setCatAvatarPreview(null);
                // alert('Размер файла не должен превышать 1 МБ.')
                notify('Размер файла не должен превышать 1 МБ.');
                // console.log('notify')
            } else {
                // setErrorMessage('');
                setCatAvatar(file);
                setCatAvatarPreview(URL.createObjectURL(file));
            }
        } else {
            notify('Не верный формат файла. Пожалуйста, загрузите файл изображения.');

            // setErrorMessage('Пожалуйста, загрузите файл изображения.');
            setCatAvatar(null);
            setCatAvatarPreview(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        triggerVibration('heavy');

        if (!catAvatar || !catName || !selectedLanguage) {

            notify('Заполните все поля и выберите изображение.');

            return;
        }

        const formData = new FormData();
        formData.append('cat_avatar', catAvatar);
        formData.append('cat_name', catName);
        formData.append('lang', selectedLanguage);

        console.log(catAvatar,catName,selectedLanguage, 'formData')

        const response = await fetch(`${DOMAIN}/api/v1/verification/`, {
            method: 'POST',
            body: formData,
            headers: {
                "X-UserId": user?.id ? `${user?.id}` : `844536969`
            }
        });

        const statusCode = response.status;

        if (response.ok) {
            const data = await response.json();
            console.log('Success:', data);
            navigate("/");
            window.location.reload()
        } else {
            if (statusCode === 403) {
                navigate("/banned");
            } else if (statusCode === 401) {
                notify('Бот не активирован');

            }
            console.log('Errorqqqq:', statusCode);
        }

    };

    if (showSlider) {

        return (

            <div style={{width: '100%', background: '#1C1F24', flex: 1}}>

                <Swiper
                    ref={swiperRef}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,

                    }}
                    // scrollbar={{draggable: false}}
                    onSwiper={(swiper) => {
                        console.log(swiper, '--')

                    }}
                    onSlideChange={(e) => {
                        setActiveSlide(e?.activeIndex)
                        console.log('slide change', e?.activeIndex)
                        if (e?.activeIndex == 3) {
                            // setShowSlider(false)
                            // localStorage.setItem('slider', 'true')
                        }
                    }}
                    style={{flex: 1}}

                >
                    <SwiperSlide>
                        <div className="slide-content">
                            <h2>Кликайте на экран и <br/> зарабатывайте монеты</h2>
                            <p>Вы можете использовать <br/> бусты и хитрые стратегии</p>
                            <img src={slide1} alt="Описание изображения 1"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content">
                            <h2>Улучшайте свою биржу</h2>
                            <p>
                                Используйте карточки, чтобы <br/> увеличить свой пассивный доход
                            </p>
                            <img src={slide2} alt="Описание изображения 1"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content">
                            <h2>Приглашайте друзей и <br/> получайте бонусы вместе</h2>
                            <p>Не забудьте пригласить <br/> своих друзей. Удачи!</p>
                            <img src={slide3} alt="Описание изображения 1"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content">
                            <h2>
                                Используйте монеты для <br/> получения вознаграждения <br/> при листинге токенов
                            </h2>
                            <p>Монеты получите и вы, и ваш друг</p>
                            <img src={slide4} alt="Описание изображения 1"/>
                        </div>
                    </SwiperSlide>
                    <div className="swiper-pagination"></div>
                </Swiper>

                <div style={{paddingLeft: 24, paddingRight: 24}}>
                    <div
                        onClick={() => {
                            triggerVibration('heavy');
                            swiperRef.current.swiper.slideNext()
                            if (activeSlide === 3) {
                                setShowSlider(false)
                                localStorage.setItem('slider', 'true')
                            }
                        }}
                        className={'yellow-btn'}>
                        <p className={'yellow-btn-text'}>Далее</p>
                    </div>
                </div>
            </div>

        );

    }

    // return (
    //     <>
    //         <form onSubmit={handleSubmit} className={'wrapper'} style={{
    //             background: '#1C1F24',
    //             padding: 24,
    //             boxSizing: 'border-box',
    //             display: "flex",
    //             flexDirection: "column",
    //             justifyContent: 'center'
    //         }}>
    //             <img className={'passport-image'} src={passport} alt="passport"/>
    //
    //             <h1 className={'title'}>Пройдите верификацию</h1>
    //             <h1 className={'sub-title'}>Введите данные своего кота</h1>
    //
    //             <div className={'input-wrapper'}>
    //                 <label className={'input-label'} htmlFor="">Введите имя кота:</label>
    //                 <input
    //                     className={'input'}
    //                     type="text"
    //                     placeholder={'Например: Леопольд'}
    //                     value={catName}
    //                     onChange={(e) => setCatName(e.target.value)}
    //                 />
    //             </div>
    //
    //             <div className={'input-wrapper'}>
    //                 <label className={'input-label'} htmlFor="">Выберите язык кота:</label>
    //                 <div className="headerLanguageWrapper" onClick={() => {
    //                     setIsOpenLngModal(true)
    //                 }}>
    //                     <div className={'lng'}>
    //                         <RuIcon/>
    //                         <span style={{marginLeft: 10}}>Русский</span>
    //                     </div>
    //                     <LngArrow style={{marginLeft: 8}}/>
    //                 </div>
    //             </div>
    //
    //             <label className={'input-wrapper'}>
    //                 <div className={'input-label'} htmlFor="file">Загрузите картинку кота:</div>
    //
    //                 {catAvatarPreview ?
    //                     <div className={'input-wrapper'}>
    //                         <label className={'input-label'} htmlFor="">Предпросмотр изображения:</label>
    //                         <img src={catAvatarPreview} alt="cat preview" className={'cat-preview'}/>
    //                     </div>
    //                     :
    //                     <img className={'download-image'} src={download} alt="download"/>
    //                 }
    //
    //                 <input
    //                     id={'file'}
    //                     style={{display: 'none'}}
    //                     type="file"
    //                     accept="image/*"
    //                     onChange={handleFileChange}
    //                 />
    //             </label>
    //
    //
    //             <button type="submit" className={'task-wrapper-right'}>
    //                 <p className={'task-text1'}>Пройти верификацию</p>
    //             </button>
    //
    //
    //             <LanguageModal
    //                 setSelectedLanguage={setSelectedLanguage}
    //                 selectedLanguage={selectedLanguage}
    //                 isOpen={isOpenLngModal}
    //                 onclose={()=>{
    //                     setIsOpenLngModal(false)
    //                 }}
    //             />
    //
    //             <ToastContainer />
    //
    //         </form>
    //     </>
    // );
}

export default Login;
