import React from "react";

function Icon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="#cb1010"
            stroke="#cb1010"
            version="1"
            viewBox="-2.4 -2.4 28.8 28.8"
            {...props}
        >
            <path
                fill="#024af6"
                strokeWidth="0"
                d="M14.099-1.806a4.05 4.05 0 00-4.198 0L.451 3.922A4.05 4.05 0 00-1.5 7.385v9.23a4.05 4.05 0 001.951 3.463l9.45 5.728a4.05 4.05 0 004.198 0l9.45-5.728a4.05 4.05 0 001.951-3.463v-9.23a4.05 4.05 0 00-1.951-3.463l-9.45-5.728z"
            ></path>
            <g>
                <g
                    fill="none"
                    stroke="#fff"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                >
                    <path d="M6 21.5L0.5 12 6 2.5 18 2.5 23.5 12 18 21.5z"></path>
                    <path d="M23.5 12L16 7.5 6 2.5 5 13.5 6 21.5 16.5 19.5z"></path>
                    <path d="M16 7.5L5 13.5 16.5 19.5z"></path>
                    <path d="M18 21.5L16.5 19.5"></path>
                    <path d="M0.5 12L5 13.5"></path>
                    <path d="M18 2.5L16 7.5"></path>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
