import React, { useState,  useEffect} from 'react';
import './AnimatedCoins.css';

const AnimatedCoins = (props) => {
    const [coins, setCoins] = useState([]);

    useEffect(() => {
        handleClick()
    }, []);
    const handleClick = () => {
        const newCoins = [];
        const numCoins = 100; // Количество монет при каждом нажатии

        for (let i = 0; i < numCoins; i++) {
            newCoins.push({
                id: Date.now() + Math.random(),
                left: Math.random() * 100, // Случайная позиция по горизонтали (в процентах)
                delay: Math.random() * 0.5 // Случайная задержка для анимации
            });
        }

        setCoins(prevCoins => [...prevCoins, ...newCoins]);

        setTimeout(() => {
            setCoins(prevCoins => prevCoins.filter(coin => !newCoins.includes(coin)));
            props.stopCoinsAnimation()
        }, 1000); // Удаляем монеты через 1 секунду
    };

    return (
        <div className="coin-container">
            {/*<button onClick={handleClick}>Запустить монеты</button>*/}
            {coins.map(({ id, left, delay }) => (
                <div key={id} className="coin" style={{ left: `${left}%`, animationDelay: `${delay}s` }}></div>
            ))}
        </div>
    );
};

export default AnimatedCoins;
