import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../Context/TelegramContext';
import '../../../assets/css/tasks.css'
import Footer from "../../../components/Footer";
import calendar from '../../../assets/images/calendar.png'
import {
    GalochkaIcon, InstaIcon,
    RightIcon,
    TgIcon, TikTokIcon,
    XIcon,
    YoutubeIcon
} from "../../../components/icons";
import MonetaIcon from '../../../assets/icons/monet.svg'

import {useNavigate} from "react-router-dom";
import SubscribeModal from "../../../components/SubscribeModal";
import AnimatedCoins from "../../../components/AnimatedCoins";
import {useLanguage} from "../../../Context/LanguageContext";
import {stringToBase64} from "../../../utils/format";
import useCheckSocNetwork from "../../../hooks/useCheckSocNetwork";
import useGetRewardPrices from "../../../hooks/useGetRewardPrices";
import Loader from "react-js-loader";
import StarsIcon from "../../../assets/icons/StarsIcon";
import CrystalIcon from "../../../assets/icons/CrystalIcon";
function City() {
    const { user, webApp } = useTelegram();
    const { t, language } = useLanguage();

    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const [shopingMallData, setShopingMallData] = useState([
        {price: '5000',  title: `${t('follow_on_telegram')}` , stars: "50"},
        {price: '5000',  title: `${t('follow_us_on')} YouTube`,  stars: "50"},
        {price: '5000',  title: `${t('follow_us_on')} TikTok`, stars: "50"},
        {price: '5000',  title: `${t('follow_us_on')} Instagram`, stars: "50"},
        {price: '5000',  title: `${t('follow_us_on')} X`, stars: "50"},
    ]);
    // const [dailyRewardDays, setDailyRewardDays] = useState([]);


    const [loader, setLoader] = useState(true);

    const [userData, setUserData] = useState(null);
    const [coinBalance, setCoinBalance] = useState('0');
    const [staminaMin, setStaminaMin] = useState('0');
    const [staminaMax, setStaminaMax] = useState('0');
    const [staminaLevel, setStaminaLevel] = useState('0');

    const [showDailyReward, setShowDailyReward] = useState(false);
    const navigate = useNavigate();
    const [isAnimating, setIsAnimating] = React.useState(false);
    const [showSubscribeModal, setShowSubscribeModal] = React.useState(false);
    const [subscribeModalType, setSubscribeModalType] = React.useState('');


    const [subCatsList, setSubCatsList] = React.useState([
        'Мои активы','Недвижимость','Транспорт', 'Акции'
    ]);
    const [activeSubCat, setActiveSubCat] = React.useState('Мои активы');

    const {subscribeData,loadingSubscribeData, errorSubscribeData, fetchCheckSocNetwork } = useCheckSocNetwork();
    const {dailyRewardDays, loadingDailyRewardDays, errorDailyRewardDays, fetchGetRewardPrices } = useGetRewardPrices();

    const [showCoinsAnimation, setShowCoinsAnimation] = useState(false);


    const getTotalPriceDailyRewardDays = () => {
        return dailyRewardDays.reduce((total, item) => {
            // Убираем запятые и преобразуем строку в число
            return total + item.bonus;
        }, 0);
    };

    // const totalPriceDailyRewardDays = dailyRewardDays.length > 0 ? getTotalPriceDailyRewardDays() : 0;

    function formatNumber(num) {
        if (num < 1000) {
            return num.toString();
        }

        const units = ["K", "M", "B", "T"];
        const order = Math.floor(Math.log10(num) / 3);
        const unitname = units[order - 1];
        const scaled = num / Math.pow(1000, order);

        // Округление до двух знаков после запятой
        const rounded = Math.floor(scaled * 100) / 100;

        return `+${rounded.toFixed(2).replace('.', '.')}${unitname}`;
    }


    const handleGetLocalData = () => {
        const coin_balance = localStorage.getItem('coin_balance');
        const data = localStorage.getItem('user');
        const stamina_min = localStorage.getItem('stamina_min');
        const stamina_max = localStorage.getItem('stamina_max');
        const stamina_level = localStorage.getItem('stamina_level');

        setUserData(JSON.parse(data));
        setStaminaMin(JSON.parse(stamina_min));
        setStaminaMax(JSON.parse(stamina_max));
        setStaminaLevel(JSON.parse(stamina_level));
        setCoinBalance(JSON.parse(coin_balance));
        setLoader(false)
    }




    // useEffect(() => {
    //     handleGetLocalData();
    //     // checkSocNetworks();
    //     // handleGetRewardPrices()
    //     // checkUser();
    // }, [user]);


    useEffect(() => {
        const interval = setInterval(() => {
            handleGetLocalData()
        }, 500);

        return () => clearInterval(interval);
    }, []);


    // useEffect(() => {
    //     setIsAnimating(false);
    //
    //     const timer = setTimeout(() => {
    //         setIsAnimating(true);
    //     }, 5); // Анимация длится 3 секунды
    //
    //     return () => clearTimeout(timer);
    // }, []);


    // const handleGetRewardPrices = async () => {
    //
    //     let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
    //
    //     const response = await fetch(`${DOMAIN}/api/v1/rewards-list/`, {
    //         method: 'GET',
    //         headers: {
    //             "X-UserId": init_data
    //         }
    //     });
    //
    //     const statusCode = response.status;
    //
    //     if (response.ok) {
    //         const data = await response.json();
    //         setDailyRewardDays(data)
    //         console.log(data, 'datadata')
    //     }
    //
    // }

    // const checkSocNetworks = async () => {
    //     let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
    //
    //     const response = await fetch(`${DOMAIN}/api/v1/social-networks/`, {
    //         method: 'GET',
    //         headers: {
    //             "X-UserId": init_data
    //         }
    //     });
    //
    //     const statusCode = response.status;
    //
    //     if (response.ok) {
    //
    //         const data = await response.json();
    //         console.log(data, 'data')
    //         if (statusCode === 200 || statusCode === 201) {
    //             // setSubscribeData(data);
    //         }
    //
    //     } else {
    //         if (statusCode === 403) {
    //             // navigate('/banned')
    //         } else if (statusCode === 401) {
    //             // navigate('/login')
    //         }
    //     }
    //
    //     setLoader(false)
    // };


    const handleClickToClicker = () => {
        console.log('click')
    }

    return (
        <>

            {(loader || loadingSubscribeData || loadingDailyRewardDays) &&
                <div className={'loader-main-wrapper'}>
                    {/*<Loader type="spinner-cub" bgColor={'#f67b1a'} color={'white'} title={""} size={60}/>*/}
                    <div className="loader"></div>

                    {/*Loading*/}
                </div>
            }

            <div className={'wrapper tasks-main-wrapper'}>

                <h1 className={`title ${isAnimating ? '' : ''}`}>
                    {/*Задания на каждый день*/}
                    {t('city_title')}
                </h1>
                <h2 className={`sub-title ${isAnimating ? '' : ''}`}>
                    {/*Вы и ваш друг получите бонусы*/}
                    {t('city_sub_title')}
                </h2>


                <div className={'moneta-wrapper'}>
                    <CrystalIcon/>
                    <div style={{fontSize: 30}} className={'moneta-value'}>1 160 000</div>
                </div>

                <div className={'how-to-get-crystal'}>Как получить кристалы?</div>


                <div className={'task-item shoping-subcat-wrapper'}>

                    {subCatsList.map((item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    setActiveSubCat(item)
                                }}
                                className={`shoping-sub-cat ${activeSubCat == item && "active"}`}
                                style={{fontSize:14}}
                            >
                                {item}
                            </div>
                        )
                    })}

                </div>


                <div className={'tasks-list-wrapper'} style={{marginBottom: 80}}>

                    <div className={'tasks-list-wrapper-top'}>
                        <p className={'tasks-list-wrapper-top-title'}>
                            {/*Ежедневные задачи*/}
                            {t('tasks_list')}
                        </p>
                    </div>


                    <div className={'refferals-wrapper'}>

                        {shopingMallData && shopingMallData.map((item, index) => {


                            return (
                                <div
                                    className={'task-item'}
                                    key={index}
                                    onClick={() => {
                                        // setShowSubscribeModal(true);
                                        // setSubscribeModalType(item?.type);
                                    }}
                                >
                                    <div className={'tg-icon-wrapper'}>
                                    </div>

                                    <div className={'refferals-item-center'}>
                                        <div style={{color: 'white'}}>{item?.title}</div>
                                        <div style={{alignItems: 'center', color: 'white', marginTop: 4}}>
                                            <div style={{display: "flex", alignItems: 'center', fontSize: 13}}>
                                                +{item?.stars} <StarsIcon style={{marginLeft: 5}}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'shoping-item-right'}>

                                        {/*<img*/}
                                        {/*    style={{marginRight: 5}}*/}
                                        {/*    className="nav__icon"*/}
                                        {/*    width="24"*/}
                                        {/*    height="24"*/}
                                        {/*    src={MonetaIcon}*/}
                                        {/*    alt="arrow icon"*/}
                                        {/*/>*/}
                                        <CrystalIcon
                                            style={{width: 19, marginRight:5}}
                                        />
                                        +{item?.price}
                                    </div>

                                </div>
                            )
                        })}

                    </div>

                </div>

            </div>

            <Footer page={'location'}/>

            {showSubscribeModal &&
                <SubscribeModal
                    isActive={showSubscribeModal}
                    onCheckClose={() => {
                        setShowCoinsAnimation(true)
                    }}
                    onClose={() => {
                        setShowSubscribeModal(false)
                    }}
                    modalType={subscribeModalType}
                    newUserData={userData}
                    subscribeData={subscribeData}
                    updateSocNetworks={() => {
                        // checkSocNetworks();
                        fetchCheckSocNetwork()
                    }}
                />

            }

            {showCoinsAnimation &&
                <AnimatedCoins
                    stopCoinsAnimation={() => {
                        setShowCoinsAnimation(false)
                        setShowSubscribeModal(false)
                        // checkSocNetworks();
                        fetchCheckSocNetwork()
                    }}
                />
            }

        </>

    );
}

export default City;

