import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/farm.css'
import Footer from "../../components/Footer";
import calendar from '../../assets/images/calendar2.png'
import purpleShadow from '../../assets/images/purpleShadow.png'
import clickerWrapper from '../../assets/images/clickerWrapper.png'
import AnimatedNumber from "../../components/AnimatedNumber";
import Header from "../../components/Header";
import {useClipboard} from "../../components/use-clipboard.ts";

import {
    Close2Icon,
    CloseIcon, CopyIcon,
    EnergyIcon2,
    IntelektIcon,
    LapkaIcon,
    MonetaIcon,
    TgIcon,
    XIcon,
    YoutubeIcon
} from "../../components/icons";
import {useNavigate} from "react-router-dom";
import ton from "../../assets/images/web3tom.png";
import {useTonConnectModal, useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {useLanguage} from "../../Context/LanguageContext";
import {stringToBase64} from "../../utils/format";

function TonConnectModal({isActive, onClose, newUserData, handleUpdateUserData}) {
    const { user, triggerVibration, webApp } = useTelegram();
    const { t, language } = useLanguage();

    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const navigate = useNavigate();

    const { open } = useTonConnectModal();
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();

    const [showAnimation, setShowAnimation] = useState(true);
    const [userData, setUserData] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [logout, setLogout] = useState(false);

    const { handleCopy, isCopied } = useClipboard(
        userFriendlyAddress,
        {
            timeout: 1500,
        }
    );

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(()=>{
        console.log(newUserData, 'newUserDatanewUserData')
        setShowAnimation(false);
        setUserData(newUserData);
    }, [isActive, newUserData])

    useEffect(()=>{

        if (userFriendlyAddress) {
            console.log('userFriendlyAddress', userFriendlyAddress);
            handleUpdateWeb3ID(userFriendlyAddress);
        }

    }, [userFriendlyAddress])


    const handleUpdateWeb3ID = async (web3id = null) => {
        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/me/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "X-UserId": `${init_data}`
            },
            body: JSON.stringify({
                web_3_id: web3id
            })
        });

        const statusCode = response.status;

        if (response.ok) {
            const data = await response.json();
            if (statusCode === 200 || statusCode === 201) {
                // if (data?.is_verified) {
                //     localStorage.setItem('user', JSON.stringify(data));
                //     console.log('Success:', statusCode,  data);
                // }
                handleUpdateUserData();
                console.log('Success handleUpdateWeb3ID:', statusCode,  data);
                setLogout(false)
            }

        } else {

            if (statusCode === 403) {
                // navigate('/banned')
            } else if (statusCode === 401) {
                // navigate('/login')
            }

            console.error('Error:', response.statusText);
            console.error('Error statusCode:', statusCode);
        }
    }

    const RenderIcon = () => {
        return (
            <div className={'pay-modal-icon-wrapper'}>
                <img style={{width:100, height:100}} src={ton}/>
            </div>
        )
    }

    const RenderDescription = () => {
        if (logout) {
            return (
                <>
                    <p style={{fontSize: 20, lineHeight:'40px', fontWeight:'bold', maxWidth: 400, margin: '20px auto'}} className={'question-sibtitle'}>
                       {/*Вы уверены что хотите отключить кошелек?*/}
                        {t('are_you_sure_you_want_to_disable_your_wallet')}
                    </p>

                </>
            )
        } else if (userData?.web_3_id) {
            return (
                <>
                    <p style={{fontSize: 30, lineHeight:'40px', fontWeight:'bold', maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                        {/*Подключай свой кошелек TON*/}
                        {t('connect_your_ton_wallet')}
                    </p>
                    <p style={{fontSize: 12, maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                        {/*Вы можете отключить или скопировать кошелек*/}
                        {t('you_can_disable_or_copy_the_wallet')}
                    </p>
                </>
            )
        } else {
            return (
                <>
                    <p style={{fontSize: 30, lineHeight:'40px', fontWeight:'bold', maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                        {/*Подключай свой кошелек TON*/}
                        {t('connect_your_ton_wallet')}
                    </p>
                    <p style={{fontSize: 14, maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                        {/*Подключай свой кошелек TON Surf и получай монеты за подписку на наши соцсети*/}
                        {t('ton_connect_desc')}
                    </p>
                </>
            )
        }
    }

    const RenderBtn = () => {

        if (logout) {

            return (
                <div style={{flex: 1, padding: '0 25',  boxSizing:'border-box'}}>

                    <div style={{flex: 1, padding: '0 25', boxSizing:'border-box'}}>
                        <div
                            onClick={async () => {
                                triggerVibration('heavy');
                                if (userFriendlyAddress) {
                                    await tonConnectUI.disconnect();
                                }
                                handleUpdateWeb3ID(null)
                            }}
                            className={'task-wrapper-right2'}
                        >
                            <p className={'task-text12'}>
                               {/*Да*/}
                                {t('yes')}

                            </p>
                        </div>
                    </div>
                    <div style={{flex: 1, padding: '0 25',  boxSizing:'border-box'}}>
                        <div
                            onClick={() => {
                                triggerVibration('heavy');
                                setLogout(false)
                            }}
                            className={'task-wrapper-right2'}
                        >
                            <p className={'task-text12'}>
                               {/*Отменить*/}
                                {t('cancel')}
                            </p>
                        </div>
                    </div>
                </div>
            )

        } else if (userData?.web_3_id) {

            return (
                <div style={{flex: 1, padding: '0 25'}}>
                    <div className={'ton-connect-btn-wallet-wrapper'}
                    >

                        <div
                            onClick={async () => {
                                triggerVibration('heavy');
                               setLogout(true)
                            }}
                            className={'ton-connect-btn-wallet-logout'}
                        >

                            <Close2Icon/>
                        </div>

                        <div className={'ton-connect-btn-wallet-container'}>

                            <div className={'ton-address-text'}>{userData?.web_3_id}</div>

                            <div
                                onClick={() => {
                                    handleCopy()
                                }}
                                className={'copy-icon-wrapper'}
                            >
                                <CopyIcon/>
                            </div>

                        </div>

                    </div>
                </div>
            )

        } else {
            return (
                <div style={{flex: 1, padding: '0 25'}}>
                    <div
                        onClick={() => {
                            triggerVibration('heavy');
                            open();
                        }}
                        className={'task-wrapper-right2 ton-connect-btn_'}
                    >

                        <p className={'task-text12'}>
                            {t('connect_your_ton_wallet')}
                        </p>
                    </div>
                </div>
            )

        }

    }



    return (
        <div className={`question-reward-wrapper subscribe-modal-wrapper ${isActive ? 'open' : ''}`}>

            <img
                className="questionpurpleShadow"
                src={purpleShadow}
            />

            <div className={`question-reward`}>
                <div onClick={() => {onClose()}} className={'close-icon'}>
                    <CloseIcon/>
                </div>

                <RenderIcon/>
                <RenderDescription/>

                {userData &&
                    <RenderBtn/>
                }

            </div>
        </div>
    );
}

export default TonConnectModal;

