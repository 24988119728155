// logger.js
import { openDB } from 'idb';
import {stringToBase64} from "./format";
// import {useTelegram} from "../Context/TelegramContext";
// const { user, triggerVibration, webApp } = useTelegram();
const DOMAIN = process.env.REACT_APP_DOMAIN;

const initDB = async () => {
    return openDB('logDB', 1, {
        upgrade(db) {
            db.createObjectStore('logs', { keyPath: 'id', autoIncrement: true });
        },
    });
};

const logEvent = async (level, message, additionalData) => {
    const db = await initDB();
    const logEntry = {
        level,
        message,
        ...additionalData,
        timestamp: new Date().toISOString(),
    };

    await db.add('logs', logEntry);
    console.log(logEntry);
};

const getLogs = async () => {
    const db = await initDB();
    return await db.getAll('logs');
};

const clearLogs = async () => {
    const db = await initDB();
    await db.clear('logs');
};


const sendLogInDb = async (init_data) => {

    const logs = await getLogs();
    console.log('Logs_:', logs);

    if (logs && logs.length > 0) {
        const response = await fetch(`${DOMAIN}/api/v1/logger/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "X-UserId": init_data
            },
            body: JSON.stringify(logs)
        });


        const statusCode = response.status;
        if (response.ok) {
            clearLogs();

        // const data = await response.json();
        //
        // clearLogs();
        //
        // if (statusCode === 200 || statusCode === 201) {
        //
        // }

        } else {

        }
    } else  {

        // alert('chka')

    }




};


export { logEvent, getLogs, clearLogs, sendLogInDb };
