import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/farm.css'
import Footer from "../../components/Footer";
import calendar from '../../assets/images/calendar2.png'
import purpleShadow from '../../assets/images/purpleShadow.png'
import {CloseIcon, DayCheckedIcon} from "../../components/icons";
import MonetaIcon from '../../assets/icons/monet.svg'

import AnimatedCoins from "../../components/AnimatedCoins";
import {useLanguage} from "../../Context/LanguageContext";
import {stringToBase64} from "../../utils/format";
function DailyReward(props) {

    const { user, triggerVibration, webApp } = useTelegram();
    const { t, language } = useLanguage();

    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const [days, setDays] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [userData, setUserData] = useState(null);
    const [coinBalance, setCoinBalance] = useState('0');
    const [staminaMin, setStaminaMin] = useState('0');
    const [staminaMax, setStaminaMax] = useState('0');
    const [staminaLevel, setStaminaLevel] = useState('0');
    const [loader, setLoader] = useState(false);

    const [getRewardData, setGetRewardData] = useState(null);

    const [showCoinsAnimation, setShowCoinsAnimation] = useState(false);



    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleGetLocalData = () => {
        const coin_balance = localStorage.getItem('coin_balance');
        const data = localStorage.getItem('user');
        const stamina_min = localStorage.getItem('stamina_min');
        const stamina_max = localStorage.getItem('stamina_max');
        const stamina_level = localStorage.getItem('stamina_level');

        const get_reward =  localStorage.getItem('get_reward');

        if (get_reward) {
            setGetRewardData(JSON.parse(get_reward));
        }

        setUserData(JSON.parse(data));
        setStaminaMin(JSON.parse(stamina_min));
        setStaminaMax(JSON.parse(stamina_max));
        setStaminaLevel(JSON.parse(stamina_level));
        setCoinBalance(JSON.parse(coin_balance));
        setLoader(false);

    }


    useEffect(() => {
        const interval = setInterval(() => {
            handleGetLocalData()
        }, 500);

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            handleGetLocalData();
            handleGetRewardPrices();
        } else {
            if (webApp?.initData && webApp?.initData != '') {
                handleGetLocalData();
                handleGetRewardPrices();
            }
        }
    }, [webApp?.initData]);

    const handleGetReward = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        const response = await fetch(`${DOMAIN}/api/v1/get-reward/`, {
            method: 'GET',
            headers: {
                "X-UserId": init_data
            }
        });

        const statusCode = response.status;

        if (response.ok) {

            const data = await response.json();
            console.log(data, 'handleGetReward')

            if (statusCode === 200 || statusCode === 201) {

                let bonus = days.find(item => item.day === userData?.reward_level)?.bonus;
                let days_ = days.find(item => item.day === userData?.reward_level)?.day;

                // let coin_balance = localStorage.getItem('coin_balance');
                // coin_balance = JSON.parse(coin_balance);
                // coin_balance = coin_balance + bonus;

                let coin_plus = localStorage.getItem('coin_plus');
                if (coin_plus) {
                    coin_plus = parseFloat(coin_plus) + parseFloat(bonus);
                    console.log(coin_plus, 'coin_plus')
                    localStorage.setItem('coin_plus', JSON.stringify(coin_plus));
                } else {
                    localStorage.setItem('coin_plus', bonus);
                    console.log(bonus, 'bonus')
                }

                localStorage.setItem('get_reward', JSON.stringify({
                    reward_level: days_,
                    reward_received: true
                }));



                console.log('Success:', statusCode,  data);

                // localStorage.setItem('user', JSON.stringify(data));
                // localStorage.setItem('coin_balance', JSON.stringify(coin_balance));
                // localStorage.setItem('stamina_min', JSON.stringify(data?.stamina));
                // localStorage.setItem('stamina_max', JSON.stringify(data?.stamina_level?.bonus));
                // localStorage.setItem('stamina_level', JSON.stringify(data?.stamina_level?.num));

                setShowCoinsAnimation(true)
                // onClose(data);


            }
        }


    }

    const handleGetRewardPrices = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/rewards-list/`, {
            method: 'GET',
            headers: {
                "X-UserId": init_data
            }
        });

        const statusCode = response.status;

        if (response.ok) {
            const data = await response.json();
            setDays(data)
            console.log(data, 'datadata')
        }

    }


    useEffect(() => {
        console.log(userData, 'userData')
    }, [userData]);

    return (

        <>
            <div className={'wrapper'} style={{
                background: '#1C1F24',
                padding: 24,
                boxSizing: 'border-box',
            }}>

                    <div className={'daily-reward'} style={{overflow: 'unset'}}>


                        <img
                            className="beeg-calendar"
                            src={calendar}
                        />

                        <h1 className={'daily-title'}>
                            {/*Ежедневная награда*/}
                            {t('daily_reward')}
                        </h1>
                        <p className={'daily-sibtitle'}>
                            {t('daily_reward_desc')}
                        </p>

                        <div className={'daily-reward-block-wrapper'}>

                            {days.map((item, index) => {

                                let border = ''

                                let  reward_level = 0;
                                let  reward_received = false;

                                if (getRewardData) {
                                    reward_level = getRewardData?.reward_level
                                    reward_received = getRewardData?.reward_received
                                } else {
                                    reward_level = userData?.reward_level
                                    reward_received = userData?.reward_received
                                }



                                if (item?.day < reward_level) {
                                    border = 'green'
                                } else if (item?.day === reward_level && !reward_received) {
                                    border = 'gold'
                                } else if (item?.day === reward_level && reward_received) {
                                    border = 'green'
                                }
                                return (
                                    <div className={`daily-reward-block-item ${border}`}>
                                        {border == 'green' &&
                                            <div className={'day-checked-icon'}>
                                                <DayCheckedIcon/>
                                            </div>
                                        }
                                        <p className={'daily-reward-block-item-text1'}>{t('day')} {item?.day}</p>
                                        <img
                                            className="nav__icon"
                                            width="24"
                                            height="24"
                                            src={MonetaIcon}
                                            alt="arrow icon"
                                        />
                                        <p className={'daily-reward-block-item-text2'}>{item?.bonus}</p>
                                    </div>
                                )
                            })}


                        </div>


                        <div
                            onClick={() => {
                                if (getRewardData) {

                                    if (!getRewardData?.reward_received) {
                                        triggerVibration('heavy');
                                        handleGetReward()
                                    }

                                } else if (!userData?.reward_received) {
                                    triggerVibration('heavy');
                                    handleGetReward()
                                }
                            }}
                            style={{flex: 1, padding: '0 25', opacity:  getRewardData && getRewardData?.reward_received ? 0.5 :  userData?.reward_received ? 0.5 : 1, marginBottom:70}}
                        >
                            <div className={'task-wrapper-right2 get-btn'}>
                                {userData?.reward_received ?
                                    <p className={'task-text12'}>{t('received')}</p>
                                    :
                                    <p className={'task-text12'}>{t('take')}</p>
                                }
                            </div>
                        </div>

                    </div>
            </div>

            <Footer page={'tasks'}/>

            {showCoinsAnimation &&
                <AnimatedCoins
                    stopCoinsAnimation={()=>{
                        setShowCoinsAnimation(false)
                    }}
                />
            }

        </>


    );
}

export default DailyReward;

