import React, {useEffect, useRef, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/airdrop.css'
import Footer from "../../components/Footer";
import shar from '../../assets/images/shar.png'
import Header from "../../components/Header";
import gift from "../../assets/images/gift.png";
import {
    CopyIcon, GalochkaIcon,
    GreenIcon,
    LineIcon,
    MonetaIcon,
    RefreshIcon,
    RightIcon,
    TgIcon,
    Transparenticon
} from "../../components/icons";

import img1 from "../../assets/images/img1.png";
import calendar from "../../assets/images/calendar.png";

import ton from "../../assets/images/web3tom.png";
import {useTonWallet, useTonConnectModal, useTonAddress} from '@tonconnect/ui-react';
import TonConnectModal from "../../components/TonConnectModal";
import {useLanguage} from "../../Context/LanguageContext";
import {stringToBase64} from "../../utils/format";

function Airdrop() {

    const { user, webApp } = useTelegram();
    const { t, language } = useLanguage();

    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const [isAnimating, setIsAnimating] = React.useState(false);
    const [showTonConnectModal, setShowTonConnectModal] = React.useState(false);

    const [userData, setUserData] = React.useState([]);
    const isMounted = useRef(false);


    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                handleUpdateUserData();
                isMounted.current = true;
            }

        } else {
            if (webApp?.initData && webApp?.initData != '') {
                handleUpdateUserData();
            }
        }
    }, [webApp?.initData]);

    useEffect(() => {
        setIsAnimating(false);

        const timer = setTimeout(() => {
            setIsAnimating(true);
        }, 5); // Анимация длится 3 секунды

        return () => clearTimeout(timer);
    }, []);



    const handleUpdateUserData = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/me`, {
            method: 'GET',
            headers: {
                "X-UserId": init_data
            }
        });

        const statusCode = response.status;

        if (response.ok) {
            const data = await response.json();
            if (statusCode === 200 || statusCode === 201) {
                localStorage.setItem('user', JSON.stringify(data));
                console.log('Success:', statusCode,  data);
                setUserData(data)
            }

        } else {

            if (statusCode === 403) {
                // navigate('/banned')
            } else if (statusCode === 401) {
                // navigate('/login')
            }

            console.error('Error:', response.statusText);
            console.error('Error statusCode:', statusCode);
        }

    };

    const handleClickToClicker = () => {
        console.log('click')
    }

    return (
        <>
            <div className={'wrapper'} style={{
                background: '#1C1F24',
                padding: 24,
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "column",
                paddingBottom: 90
            }}>

                <h1
                    style={{
                        maxWidth: 300,
                        margin: '0 auto'
                    }}
                    className={`title ${isAnimating ? '' : ''}`}>
                    {/*Приготовьтесь, скоро <br/> состоится Aidrop*/}
                    {t('airdrop_title')}
                </h1>
                <img className={'shar-image'} src={shar}/>

                <div className={'tasks-list-wrapper'}>

                    <div className={'tasks-list-wrapper-top'}>
                        <p className={'tasks-list-wrapper-top-title'}>
                            {/*Список заданий*/}
                            {t('tasks_list')}
                        </p>
                    </div>


                    <div className={'refferals-wrapper'}>

                        <div style={{background:'dodgerblue'}} className={'task-item'}>

                            <img className={'task-image'} src={ton}/>

                            <div className={'refferals-item-center'}>
                                <div
                                    onClick={() => {
                                        // navigate('/daily-reward')
                                        setShowTonConnectModal(true)
                                    }}
                                    style={{color: 'white'}}
                                >
                                    {/*Подключай свой кошелек TON*/}
                                    {t('connect_your_ton_wallet')}
                                </div>

                            </div>

                            <div className={'refferals-item-right'}>
                                {userData?.web_3_id ? <GalochkaIcon/> : <RightIcon style={{width: 24, height: 24, fill: 'transparent'}}/>}
                            </div>

                        </div>
                    </div>


                </div>


                <div className={'steps-wrapper'}>
                    <div className={'steps-item'}>
                        <div style={{
                            position: 'relative',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <GreenIcon/>
                            <div className={'line one-line'}></div>
                        </div>
                        <div className={'steps-item-text'}>{t('negotiations_with_exchanges')}</div>
                    </div>

                    <div className={'steps-item'}>
                        <div style={{
                            width: 48,
                            height: 48,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Transparenticon/>
                            <div className={'line'}></div>
                        </div>
                        <div className={'steps-item-text'}>{t('negotiations_with_market_makers')}</div>
                    </div>

                    <div className={'steps-item'}>
                        <div style={{
                            width: 48,
                            height: 48,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Transparenticon/>
                            <div className={'line'}></div>
                        </div>
                        <div className={'steps-item-text'}>{t('key_partnerships_outlined')}</div>
                    </div>

                    <div className={'steps-item'}>
                        <div style={{
                            width: 48,
                            height: 48,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Transparenticon/>
                            <div className={'line last-line'}></div>
                        </div>
                        <div className={'steps-item-text'}>{t('list_of_landing_tasks')}</div>
                    </div>

                </div>
            </div>

            <Footer page={'airdrop'}/>

            <TonConnectModal
                isActive={showTonConnectModal}
                onClose={()=>{
                    setShowTonConnectModal(false)
                }}
                newUserData={userData}
                handleUpdateUserData={handleUpdateUserData}
            />
        </>

    );
}

export default Airdrop;

