import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TelegramProvider } from './Context/TelegramContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { LanguageProvider, useLanguage } from './Context/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <TelegramProvider>
          <LanguageProvider>
              <Router>
                  <App />
              </Router>
          </LanguageProvider>
      </TelegramProvider>
  </React.StrictMode>
);

reportWebVitals();
