import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../Context/TelegramContext';
import '../../../assets/css/banned.css'
import Footer from "../../../components/Footer";
import banned from '../../../assets/images/banned.png'
import Header from "../../../components/Header";
import gift from "../../../assets/images/gift.png";
import {
    CopyIcon,
    GreenIcon,
    LineIcon,
    MonetaIcon,
    RefreshIcon,
    RightIcon,
    TgIcon,
    Transparenticon
} from "../../../components/icons";
import img1 from "../../../assets/images/img1.png";
import calendar from "../../../assets/images/calendar.png";

function Banned() {
    const { user } = useTelegram();

    useEffect(() => {
        console.log(user, 'user')
    }, []);


    const handleClickToClicker = () => {
        console.log('click')
    }

    return (
        <>
            <div className={'wrapper'} style={{
                background: '#1C1F24',
                padding: 24,
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center'
            }}>

                <h1 className={'title'}>Лавочка прикрыта !!!</h1>
                <h1 className={'sub-title'}>Ваш аккаунт заблокирован, вы <br/> пытались читерить и обмануть систему</h1>

                <img
                    className={'banned-image'}
                    src={banned}
                />


                <div className={'task-wrapper-right'}>
                    <p className={'task-text1'}>Написать в поддержку</p>
                </div>

            </div>

        </>

    );
}

export default Banned;

