import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const LinkHref = ({ className, ...props }) => {
    return (
        <RouterLink
            className={`text-primary underline ${className || ''}`}
            {...props}
        />
    );
};

export default LinkHref;
