import React, {createContext, useState, useContext, useEffect} from 'react';
import ch from '../../translations/ch.json';
import de from '../../translations/de.json';
import en from '../../translations/en.json';
import es from '../../translations/es.json';
import fr from '../../translations/fr.json';
import it from '../../translations/it.json';
import ru from '../../translations/ru.json';
import kz from '../../translations/ru.json';
import pt from '../../translations/ru.json';
import tr from '../../translations/tr.json';
import uk from '../../translations/uk.json';
import uz from '../../translations/uz.json';

const Index = createContext();

const translations = { ch,de, en, es,fr, it,  kz, pt, ru, tr, uk, uz };

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');

    useEffect(()=>{
        let lng = localStorage.getItem('language')
        if (lng) {
            lng = JSON.parse(lng)
            console.log(lng, 'lng--------')
            setLanguage(lng)
        } else {
            lng = 'ru'
            console.log(lng, 'lng--------')
            setLanguage(lng)
        }

    }, [])
    const t = (key) => {

        return translations[language][key] || key;
    };

    return (
        <Index.Provider value={{ language, setLanguage, t }}>
            {children}
        </Index.Provider>
    );
};

export const useLanguage = () => useContext(Index);
