// // import React, { useState, useRef, useEffect } from 'react';
// // import '../assets/css/farm.css';
// //
// // const AnimatedNumber = (props) => {
// //     const [numbers, setNumbers] = useState([]);
// //     const wrapperRef = useRef(null);
// //
// //     const handleClick = (event) => {
// //
// //         let  stamina_min = localStorage.getItem('stamina_min');
// //
// //         stamina_min = parseFloat(stamina_min);
// //
// //         if (stamina_min < props?.userData?.attack_level?.bonus) {
// //             return false
// //         }
// //
// //         // event.preventDefault(); // Prevent default touch behavior
// //
// //         const newNumbers = [];
// //         const wrapper = wrapperRef.current;
// //
// //         // Check if touch events are available
// //         const touches = event.touches || [event];
// //
// //         for (const touch of touches) {
// //             const rect = wrapper.getBoundingClientRect();
// //             const x = touch.clientX - rect.left;
// //             const y = touch.clientY - rect.top;
// //
// //             const newNumber = Math.floor(Math.random() * 100) + 1;
// //             newNumbers.push({ id: Date.now() + Math.random(), value: `+${props?.number || newNumber}`, x, y });
// //         }
// //
// //         setNumbers(prevNumbers => [...prevNumbers, ...newNumbers]);
// //
// //         props.handleclick(touches.length);
// //     };
// //
// //     return (
// //         <div
// //             ref={wrapperRef}
// //             className="tap-main-block-wrapper"
// //             style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 999, top: 0, left: 0 }}
// //             onTouchStart={handleClick} // Handle touch events
// //         >
// //             <div>
// //                 {numbers.map(({ id, value, x, y }) => (
// //                     <div
// //                         key={id}
// //                         className="animated-number"
// //                         style={{
// //                             top: y,
// //                             left: x,
// //                         }}
// //                     >
// //                         {value}
// //                     </div>
// //                 ))}
// //             </div>
// //         </div>
// //     );
// // };
// //
// // export default AnimatedNumber;
//
//
// import React, { useState, useRef, useEffect, useCallback } from 'react';
// import '../assets/css/farm.css';
//
// const AnimatedNumber = (props) => {
//     const [numbers, setNumbers] = useState([]);
//     const wrapperRef = useRef(null);
//
//     const handleClick = useCallback((event) => {
//         let stamina_min = localStorage.getItem('stamina_min');
//         stamina_min = parseFloat(stamina_min);
//
//         if (stamina_min < props?.userData?.attack_level?.bonus) {
//             return false;
//         }
//
//         const newNumbers = [];
//         const wrapper = wrapperRef.current;
//         const touches = event.touches || [event];
//
//         for (const touch of touches) {
//             const rect = wrapper.getBoundingClientRect();
//             const x = touch.clientX - rect.left;
//             const y = touch.clientY - rect.top;
//
//             const newNumber = Math.floor(Math.random() * 100) + 1;
//             newNumbers.push({ id: Date.now() + Math.random(), value: `+${props?.number || newNumber}`, x, y });
//         }
//
//         setNumbers(prevNumbers => [...prevNumbers, ...newNumbers]);
//         props.handleclick(touches.length);
//     }, [props]);
//
//     useEffect(() => {
//         return () => {
//             setNumbers([]); // Clean up state when component unmounts
//         };
//     }, []);
//
//     return (
//         <div
//             ref={wrapperRef}
//             className="tap-main-block-wrapper"
//             style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 999, top: 0, left: 0 }}
//             onTouchStart={handleClick} // Handle touch events
//         >
//             <div>
//                 {numbers.map(({ id, value, x, y }) => (
//                     <div
//                         key={id}
//                         className="animated-number"
//                         style={{
//                             top: y,
//                             left: x,
//                         }}
//                     >
//                         {value}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };
//
// export default AnimatedNumber;


import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../assets/css/farm.css';

const AnimatedNumber = (props) => {
    const [numbers, setNumbers] = useState([]);
    const wrapperRef = useRef(null);
    const lastClickTimeRef = useRef(Date.now());

    const handleClick = useCallback((event) => {

        props?.disableScroll()

        let stamina_min = localStorage.getItem('stamina_min');
        stamina_min = parseFloat(stamina_min);

        if (stamina_min < props?.userData?.attack_level?.bonus) {
            return false;
        }

        const newNumbers = [];
        const wrapper = wrapperRef.current;
        const touches = event.touches || [event];

        for (const touch of touches) {
            const rect = wrapper.getBoundingClientRect();
            const x = touch.clientX - rect.left;
            const y = touch.clientY - rect.top;

            const newNumber = Math.floor(Math.random() * 100) + 1;
            newNumbers.push({ id: Date.now() + Math.random(), value: `+${props?.number || newNumber}`, x, y });
        }

        setNumbers(prevNumbers => [...prevNumbers, ...newNumbers]);
        props.handleclick(touches.length);

        lastClickTimeRef.current = Date.now();
    }, [props]);

    useEffect(() => {
        const checkInactivity = () => {
            const now = Date.now();
            if (now - lastClickTimeRef.current >= 1000) {

                props?.enableScroll()
                lastClickTimeRef.current = now; // Reset last click time to prevent multiple alerts
            }
        };

        const intervalId = setInterval(checkInactivity, 1000); // Check every second

        return () => {
            clearInterval(intervalId);
            setNumbers([]); // Clean up state when component unmounts
        };
    }, []);

    return (
        <div
            ref={wrapperRef}
            className="tap-main-block-wrapper"
            style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 999, top: 0, left: 0 }}
            onTouchStart={handleClick} // Handle touch events
        >
            <div>
                {numbers.map(({ id, value, x, y }) => (
                    <div
                        key={id}
                        className="animated-number"
                        style={{
                            top: y,
                            left: x,
                        }}
                    >
                        {value}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AnimatedNumber;
