import React, {useEffect, useRef, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/airdrop.css'
import Footer from "../../components/Footer";
import CrystalIcon from '../../assets/icons/CrystalIcon'
import {
    CopyIcon, GalochkaIcon,
    GreenIcon,
    LineIcon,
    MonetaIcon,
    RefreshIcon,
    RightIcon,
    TgIcon,
    Transparenticon
} from "../../components/icons";


import ton from "../../assets/images/web3tom.png";
import {useLanguage} from "../../Context/LanguageContext";
import {stringToBase64} from "../../utils/format";
import {Link} from "react-router-dom";

function Location() {

    const { user, webApp } = useTelegram();
    const { t, language } = useLanguage();

    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const [isAnimating, setIsAnimating] = React.useState(false);
    const [showTonConnectModal, setShowTonConnectModal] = React.useState(false);

    const [userData, setUserData] = React.useState([]);
    const isMounted = useRef(false);


    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                handleUpdateUserData();
                isMounted.current = true;
            }

        } else {
            if (webApp?.initData && webApp?.initData != '') {
                handleUpdateUserData();
            }
        }
    }, [webApp?.initData]);

    useEffect(() => {
        setIsAnimating(false);

        const timer = setTimeout(() => {
            setIsAnimating(true);
        }, 5); // Анимация длится 3 секунды

        return () => clearTimeout(timer);
    }, []);



    const handleUpdateUserData = async () => {

        // let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();
        //
        // const response = await fetch(`${DOMAIN}/api/v1/me`, {
        //     method: 'GET',
        //     headers: {
        //         "X-UserId": init_data
        //     }
        // });
        //
        // const statusCode = response.status;
        //
        // if (response.ok) {
        //     const data = await response.json();
        //     if (statusCode === 200 || statusCode === 201) {
        //         localStorage.setItem('user', JSON.stringify(data));
        //         console.log('Success:', statusCode,  data);
        //         setUserData(data)
        //     }
        //
        // } else {
        //
        //     if (statusCode === 403) {
        //         // navigate('/banned')
        //     } else if (statusCode === 401) {
        //         // navigate('/login')
        //     }
        //
        //     console.error('Error:', response.statusText);
        //     console.error('Error statusCode:', statusCode);
        // }

    };


    return (
        <>
            <div className={'wrapper'} style={{
                background: '#1C1F24',
                padding: 24,
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "column",
                paddingBottom: 90
            }}>

                <h1 style={{maxWidth: 300, margin: '0 auto'}} className={`title`}>
                    {t('location_title')}
                </h1>

                <h1 style={{maxWidth: 300, margin: '0 auto'}} className={`sub-title `}>
                    {t('location_subtitle')}
                </h1>

                {/*<img className={'shar-image'} src={CrystalIcon}/>*/}

               <div className={'crystal-total-wrapper'}>

                   <div className={'crystal-total-contain'}>
                       <CrystalIcon/>
                       <div className={'crystal-total'}>1600</div>
                   </div>
                   <div className={'how-to-get-crystal'}>Как получить кристалы?</div>
               </div>


                <div className={'location-road-map-wrapper'}>
                    <div className={'location-road-map-item-wrapper'}>
                        <div className={'location-road-map-item-link'}>
                            <div className={'location-road-map-item-link-title'}>Торгуй на бирже</div>
                            <Link to={'/birzha'} className={'location-road-map-item-link-btn'}>Перейти на биржу</Link>
                        </div>

                        <div className={'location-road-map-item-image'}>

                        </div>
                    </div>

                    <div className={'location-road-map-item-wrapper'}>

                        <div className={'location-road-map-item-image'}>

                        </div>

                        <div className={'location-road-map-item-link'}>
                            <div className={'location-road-map-item-link-title'}>Торговый центр</div>
                            <Link to={'/shopping-mall'} className={'location-road-map-item-link-btn'}>В торговый центр</Link>
                        </div>

                    </div>

                    <div className={'location-road-map-item-wrapper'}>
                        <div className={'location-road-map-item-link'}>
                            <div className={'location-road-map-item-link-title'}>Город</div>
                            <Link to={'/city'} className={'location-road-map-item-link-btn'}>Отправиться а город</Link>
                        </div>

                        <div className={'location-road-map-item-image'}>

                        </div>
                    </div>

                </div>


            </div>

            <Footer page={'location'}/>


        </>

    );
}

export default Location;

