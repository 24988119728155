import React, {useEffect, useRef, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import { useLanguage } from '../../Context/LanguageContext';

import '../../assets/css/farm.css'
import Footer from "../../components/Footer";
import catLogo from '../../assets/images/cat.png'
import daily from '../../assets/images/daily.png'
import clickerWrapper from '../../assets/images/clickerWrapper.png'
import AnimatedNumber from "../../components/AnimatedNumber";
import Header from "../../components/Header";
// import {MonetaIcon} from "../../components/icons";

import MonetaIcon from '../../assets/icons/monet.svg'

import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Loader from "react-js-loader";
import GetOflinePannyModal from "../../components/GetOflinePannyModal";
import AnimatedCoins from "../../components/AnimatedCoins";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper/modules";
import slide1 from "../../assets/images/slider/cs1.png";
import slide2 from "../../assets/images/slider/cs2.png";
import slide3 from "../../assets/images/slider/cs4.png";
import slide4 from "../../assets/images/slider/cs3.png";
import * as Sentry from '@sentry/react';
import { logEvent, getLogs, clearLogs } from '../../utils/logger';

function Farm() {
    const { t, language } = useLanguage();

    const swiperRef = useRef(null);

    const { user, triggerVibration } = useTelegram();
    const navigate = useNavigate();
    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const [coinBalance, setCoinBalance] = useState('0');
    const [taped, setTaped] = useState(false);

    const [userData, setUserData] = useState(null);
    const [staminaMin, setStaminaMin] = useState('0');
    const [staminaMax, setStaminaMax] = useState('0');
    const [staminaLevel, setStaminaLevel] = useState('0');
    const [loader, setLoader] = React.useState(true);
    const [isAnimating, setIsAnimating] = React.useState(false);

    const [showOfflinePannyModal, setShowOfflinePannyModal] = React.useState(false);
    const [showCoinsAnimation, setShowCoinsAnimation] = useState(false);

    const [showSlider, setShowSlider] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [disableScroll, setDisableScroll] = useState(false);

    const handleViewLogs = async () => {
        const logs = await getLogs();
        console.log('Logs:', logs);
    };

    const handleGetLocalData = () => {

        // handleViewLogs();

        const coin_balance = localStorage.getItem('coin_balance');
        const data = localStorage.getItem('user');
        const stamina_min = localStorage.getItem('stamina_min');
        const stamina_max = localStorage.getItem('stamina_max');
        const stamina_level = localStorage.getItem('stamina_level');
        const offlien_panny_is_showed = localStorage.getItem('offlien_panny_is_showed');
        const startSlider = localStorage.getItem('startSlider');

        setUserData(JSON.parse(data));
        setStaminaMin(JSON.parse(stamina_min));
        setStaminaMax(JSON.parse(stamina_max));
        setStaminaLevel(JSON.parse(stamina_level));

        let coin_balance_ = JSON.parse(coin_balance);
        setCoinBalance(coin_balance_.toLocaleString('ru-RU'));

        if (!offlien_panny_is_showed && JSON.parse(data)?.offline_income > 0) {
            setShowOfflinePannyModal(true);
        }

        if (startSlider) {
            setShowSlider(false);
        } else {
            setShowSlider(true);
            // setShowSlider(JSON.parse(data)?.first_login)
        }


        setLoader(false)

    }

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {

        const interval = setInterval(() => {
            handleGetLocalData()
        }, 100);

        return () => clearInterval(interval);
    }, []);


    // useEffect(() => {
    //     handleGetLocalData()
    // }, [user]);


    useEffect(() => {
        setIsAnimating(false);

        const timer = setTimeout(() => {
            setIsAnimating(true);
        }, 50); // Анимация длится 3 секунды

        return () => clearTimeout(timer);
    }, []);



    const handleClickToClicker = (fingers) => {

        setTaped(true)
        triggerVibration('heavy');

        // Отнимаем стамину - энергию при клике на кота
        let  stamina_min = localStorage.getItem('stamina_min');
        let  stamina_max = localStorage.getItem('stamina_max');
        let  new_stamina = parseFloat(stamina_min) - parseFloat(userData?.attack_level?.bonus);

        stamina_min = parseFloat(stamina_min);
        stamina_max = parseFloat(stamina_max);

        if (stamina_min > userData?.attack_level?.bonus) {

            // localStorage.setItem('stamina_min',JSON.stringify(new_stamina) );
            let  stamina_minus = localStorage.getItem('stamina_minus');
            if (stamina_minus) {
                stamina_minus = parseFloat(stamina_minus) + parseFloat(userData?.attack_level?.bonus);
                localStorage.setItem('stamina_minus', stamina_minus);
            } else {
                localStorage.setItem('stamina_minus', userData?.attack_level?.bonus);
            }

            //  Прибовляем баланс при клиеке на кота
            let coin_balance = localStorage.getItem('coin_balance');
            let attack_level =  userData?.attack_level?.bonus;
            let new_blance = parseFloat(coin_balance) + parseFloat(attack_level)

            // localStorage.setItem('coin_balance', JSON.stringify(new_blance));

            let  coin_plus = localStorage.getItem('coin_plus');
            if (coin_plus) {
                coin_plus = parseFloat(coin_plus) + parseFloat(attack_level);
                localStorage.setItem('coin_plus', JSON.stringify(coin_plus));
            } else {
                localStorage.setItem('coin_plus', attack_level);
            }

            logEvent('info', 'Tap Btn click', {
                old_stamina_min: stamina_min,
                old_stamina_max: stamina_max,
                new_stamina_min: new_stamina,
                old_coin_balance: parseFloat(coin_balance),
                new_coin_balance: new_blance,
                attack_level: attack_level,
                action: 'click'
            });
            // console.log(coin_balance,attack_level )
            // console.log(new_blance, 'new_blance')
        }

        setTimeout(()=>{
            setTaped(false)
        }, 100)

    }


    const handleTouchStart = (event) => {
        const touches = event.touches.length;
        alert(`Touched with ${touches} fingers!`)
    };

    const handleTouchEnd = (event) => {
        // Reset the message if no fingers are touching the screen

        if (event.touches.length === 0) {
            // setMessage('Waiting for touch...');
        }

    };


    if (showSlider) {
        return (
            <div className={'slider-main-wrapper__'} style={{width: '100%', background: '#1C1F24', flex: 1, paddingTop:20}}>

                <Swiper
                    ref={swiperRef}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,

                    }}
                    // scrollbar={{draggable: false}}
                    onSwiper={(swiper) => {
                        console.log(swiper, '--')

                    }}
                    onSlideChange={(e) => {
                        setActiveSlide(e?.activeIndex)
                        console.log('slide change', e?.activeIndex)
                        if (e?.activeIndex == 3) {
                            // setShowSlider(false)
                            // localStorage.setItem('slider', 'true')
                        }
                    }}
                    style={{flex: 1}}

                >
                    <SwiperSlide>
                        <div className="slide-content">
                            <h2 style={{maxWidth:300, margin:'10px auto'}}>
                                {t('slide1_title')}
                            </h2>
                            <p style={{maxWidth:250, margin:'10px auto', marginBottom:10}}>
                                {t('slide1_sub_title')}
                            </p>
                            <img src={slide1} alt="Описание изображения 1"/>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="slide-content">
                            <h2>   {t('slide2_title')}</h2>
                            <p style={{maxWidth:250, margin:'10px auto', marginBottom:10}}>

                                {t('slide2_sub_title')}
                            </p>
                            <img src={slide2} alt=""/>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="slide-content">
                            <h2 style={{maxWidth:350, margin:'10px auto'}}> {t('slide3_title')}</h2>
                            <p style={{maxWidth:250, margin:'10px auto', marginBottom:10}}>  {t('slide3_sub_title')} </p>
                            <img src={slide3} alt="Описание изображения 1"/>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="slide-content">

                            <h2  style={{maxWidth:350, margin:'0 auto'}}>
                                {t('slide4_title')}
                            </h2>

                            <p> {t('slide4_sub_title')} </p>

                            <img src={slide4} alt="Описание изображения 1"/>
                        </div>
                    </SwiperSlide>

                    <div className="swiper-pagination"></div>
                </Swiper>

                <div className={'slider-btn-wrapper'} style={{paddingLeft: 24, paddingRight: 24}}>
                    <div
                        onClick={() => {
                            triggerVibration('heavy');
                            swiperRef.current.swiper.slideNext()
                            console.log(activeSlide)
                            if (activeSlide === 3) {
                                 setShowSlider(false)
                                 localStorage.setItem('startSlider', 'true');
                            }
                        }}
                        className={'yellow-btn'}>
                        <p className={'yellow-btn-text'}>{t('next')}</p>
                    </div>
                </div>

            </div>
        );
    }


    return (
        <>
            {loader &&
                <div className={'loader-main-wrapper'}>
                    <div className="loader"></div>
                </div>
            }

            <div className={`wrapper farm-main-wrapper ${disableScroll && 'disable-scroll' } `}>

                <Header
                    coinBalance={coinBalance}
                    userData={userData}
                    staminaMin={staminaMin}
                    staminaMax={staminaMax}
                    staminaLevel={staminaLevel}
                />

                <div style={{flex:1, display:"flex", flexDirection: "column", justifyContent: "center"}}>

                    <div className={'moneta-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                        />
                        <div className={'moneta-value'}>{coinBalance}</div>
                    </div>

                    <div className={`clicker-farm-main-wrapper ${isAnimating ? 'show' : ''}`} style={{position: 'relative'}}>
                        <div className={`clicker-farm ${taped ? 'active' : ''} `}>
                            <img
                                className="farm-clicker-image-wrapper "
                                src={clickerWrapper}
                            />
                            {userData?.cat_level?.avatar &&
                                <img
                                    className="farm-clicker-image"
                                    src={userData?.cat_level?.avatar}
                                    // src={userData?.cat_level?.avatar}
                                />
                            }

                        </div>

                        <AnimatedNumber
                            disableScroll={()=>{
                                setDisableScroll(true)
                            }}
                            enableScroll={()=>{
                                setDisableScroll(false)
                            }}
                            handleclick={(fingers)=>{
                                handleClickToClicker(fingers)
                            }}
                            number={userData?.attack_level?.bonus}
                            userData={userData}
                        />

                    </div>

                    <div style={{marginTop:20}} className={'level-name'}>
                        {/*{userData?.cat_level?.league[`name_${userData?.lang}`] || ''}*/}
                        {/*КриптоБрокер*/}
                        {t('crypto_broker')}
                    </div>
                    <div className={'boss-name'}>
                        {userData?.cat_level?.league[`name_${language}`] || ''} \ {userData?.cat_level?.num || ''}-{t('level_lowercase')}
                    </div>
                </div>

            </div>

            <Footer page={'farm'}/>


            <GetOflinePannyModal
                isActive={showOfflinePannyModal}
                onClose={() => {
                    localStorage.setItem('coin_plus', userData?.offline_income);
                    setShowCoinsAnimation(true);
                    setTimeout(()=>{
                        setShowOfflinePannyModal(false);
                    }, 1500)

                    localStorage.setItem('offlien_panny_is_showed', 'true');
                }}
                newUserData={userData}

            />

            {showCoinsAnimation &&
                <AnimatedCoins
                    stopCoinsAnimation={()=>{
                        setShowOfflinePannyModal(false)
                        setShowCoinsAnimation(false)
                    }}
                />
            }
        </>

    );
}

export default Farm;

// Аналогично для остальных страниц (Referrals.js, Landing.js, Zadaniya.js)
