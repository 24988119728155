import React from 'react';
import '../assets/css/footer.css'
import {AirdropIcon, FarmIcon, RefferalsIcon, SkillsIcon, TasksIcon} from "./icons";
import LinkHref from "./LinkHref/LinkHref";
import {useTelegram} from "../Context/TelegramContext";
import {useLanguage} from "../Context/LanguageContext";

function Footer({page}) {

    const { triggerVibration } = useTelegram();
    const { t } = useLanguage();

    const handleVibrate = () => {
        triggerVibration('heavy');
    }
    return (
        <footer>
            <LinkHref onClick={handleVibrate} to="/" className="footer-item">
                <div className={'footer-item-icon'}>
                    <FarmIcon active={page == 'farm' ? true : false}/>
                </div>
                <span className={'footer-item-title'} style={page == 'farm' ? {color: 'white'} : {}}>{t('farm')}</span>
            </LinkHref>


            <LinkHref onClick={handleVibrate} to={'/skills'} className={'footer-item'}>
                <div className={'footer-item-icon'}>
                    <SkillsIcon active={page == 'skills' ?  true : false}/>
                </div>
                <span className={'footer-item-title'} style={page == 'skills' ? {color: 'white'} : {}}>{t('skills')}</span>
            </LinkHref>

            <LinkHref onClick={handleVibrate} to="/refferals" className={'footer-item'}>
                <div className={'footer-item-icon'}>
                    <RefferalsIcon active={page == 'refferals' ?  true : false}/>
                </div>
                <span className={'footer-item-title'}  style={page == 'refferals' ? {color: 'white'} : {}}>{t('friends')}</span>
            </LinkHref>

            <LinkHref onClick={handleVibrate} to="/tasks" className={'footer-item'}>
                <div className={'footer-item-icon'}>
                    <TasksIcon active={page == 'tasks' ?  true : false}/>
                </div>
                <span className={'footer-item-title'} style={page == 'tasks' ? {color: 'white'} : {}}>{t('tasks')}</span>
            </LinkHref>

            <LinkHref onClick={handleVibrate} to={'/airdrop'} className={'footer-item'}>
                <div className={'footer-item-icon'}>
                    <AirdropIcon active={page == 'airdrop' ?  true : false}/>
                </div>
                <span className={'footer-item-title'} style={page == 'airdrop' ? {color: 'white'} : {}}>{t('airdrop')}</span>
            </LinkHref>
        </footer>
    );
}

export default Footer;
