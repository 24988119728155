import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import {useLanguage} from "../../Context/LanguageContext";

import '../../assets/css/skills.css'
import Footer from "../../components/Footer";

import lock from '../../assets/images/lock.png'
import energia from '../../assets/images/ref/energia.png'
import lapka from '../../assets/images/ref/lapka.png'
import intelekt from '../../assets/images/ref/intelekt.png'

import clickerWrapper from '../../assets/images/clickerWrapper.png'
import catLogo from '../../assets/images/cat.png'
import lines from '../../assets/images/lines.png'
import AnimatedNumber from "../../components/AnimatedNumber";
import Header from "../../components/Header";
import {LockIcon, Question3Icon,  EnergyIcon2, IntelektIcon, LapkaIcon} from "../../components/icons";
import MonetaIcon from '../../assets/icons/monet.svg'

import PayModal from "../../components/PayModal";
import AnimatedCoins from "../../components/AnimatedCoins";
import Loader from "react-js-loader";


function Skills() {
    const { user, triggerVibration } = useTelegram();
    const { t, language } = useLanguage();

    const [coinBalance, setCoinBalance] = useState('0');
    const [userData, setUserData] = useState(null);
    const [staminaMin, setStaminaMin] = useState('0');
    const [staminaMax, setStaminaMax] = useState('0');
    const [staminaLevel, setStaminaLevel] = useState('0');
    const [loader, setLoader] = useState(true);

    const [showPayModal, setShowPayModal] = useState(false);
    const [payModalType, setPayModalType] = useState('');

    const [showCoinsAnimation, setShowCoinsAnimation] = useState(false);


    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const handleGetLocalData = () => {
        const coin_balance = localStorage.getItem('coin_balance');
        const data = localStorage.getItem('user');
        const stamina_min = localStorage.getItem('stamina_min');
        const stamina_max = localStorage.getItem('stamina_max');
        const stamina_level = localStorage.getItem('stamina_level');

        setUserData(JSON.parse(data));
        setStaminaMin(JSON.parse(stamina_min));
        setStaminaMax(JSON.parse(stamina_max));
        setStaminaLevel(JSON.parse(stamina_level));
        setCoinBalance(JSON.parse(coin_balance));

        setLoader(false)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            handleGetLocalData()

        }, 500); // Интервал в миллисекундах (здесь 1000 = 1 секунда)

        return () => clearInterval(interval);
    }, []);


    // useEffect(() => {
    //     handleGetLocalData()
    // }, []);


    const handleClickToClicker = () => {
        console.log('click')

    }

    return (
        <>
            {loader &&
                <div className={'loader-main-wrapper'}>
                    {/*<Loader type="spinner-cub" bgColor={'#f67b1a'} color={'white'} title={""} size={60}/>*/}
                    {/*Loading*/}
                    <div className="loader"></div>

                </div>
            }

            <div
                className={'wrapper'}
                 style={{
                     background: '#1C1F24',
                     padding:24,
                     boxSizing:'border-box',
                     display:"flex",
                     flexDirection:"column",
                     paddingBottom: 90
                 }}
            >

                <Header
                    coinBalance={coinBalance}
                    userData={userData}
                    staminaMin={staminaMin}
                    staminaMax={staminaMax}
                    staminaLevel={staminaLevel}
                />


                <div style={{flex:1, display:"flex", flexDirection: "column", justifyContent: "center", marginTop:30}}>

                    <div className={"refferals-wrapper"}>

                        <div className="lines-image-wrapper">
                            <img
                                className="lines-image"
                                src={lines}
                            />
                        </div>

                        <div className={"refferals-wrapper-top"}>
                            <div className={"lock-item"}>
                                <div className={"lock-item-name"}>
                                    {t('lapka')}
                                </div>
                                <div className={"lock-item-level "}>
                                    {userData?.next_attack_level?.num ? userData?.next_attack_level?.num+1 : ''} {t('level_lowercase')}
                                </div>
                                <div className={"lock-item-icon"}>
                                    {/*<LockIcon/>*/}
                                    <img
                                        className=""
                                        src={lock}
                                    />
                                </div>
                            </div>
                            <div className={"lock-item"}>
                                <div className={"lock-item-name"}>
                                    {t('intelligent')}
                                </div>
                                <div className={"lock-item-level "}>
                                    {userData?.next_intelligence_level?.num ? userData?.next_intelligence_level?.num + 1 : ''} {t('level_lowercase')}
                                </div>
                                <div className={"lock-item-icon"}>
                                    <img
                                        className=""
                                        src={lock}
                                    />
                                </div>
                            </div>
                            <div className={"lock-item"}>
                                <div className={"lock-item-name"}>
                                    {t('energy')}
                                </div>
                                <div className={"lock-item-level "}>
                                    {userData?.next_stamina_level?.num ? userData?.next_stamina_level?.num + 1 : ''} {t('level_lowercase')}
                                </div>
                                <div className={"lock-item-icon"}>
                                    {/*<LockIcon/>*/}
                                    <img
                                        className=""
                                        src={lock}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"refferals-wrapper-center"}>
                            <div
                                // onClick={handleClickToClicker}
                                className={'clicker'}
                            >
                                <img
                                    className="clicker-image-wrapper"
                                    src={clickerWrapper}
                                />
                                <img
                                    className="clicker-image"
                                    src={userData?.next_cat_level?.avatar}
                                />
                                <div className={'boss-data'}>
                                    <div className={'bosss-name'}>
                                        {/*{userData?.cat_level?.league[`name_${userData?.lang}`] || ''}*/}
                                        {/*КриптоБрокер*/}
                                        {t('crypto_broker')}
                                    </div>
                                    <div className={'bosss-level '}>
                                        {userData?.next_cat_level?.num ? userData?.next_cat_level?.num : '' } {t('level_lowercase')}
                                    </div>
                                    <div
                                        onClick={() => {
                                            // if (userData?.intelligence_level?.num > userData?.cat_level.num ) {
                                            setShowPayModal(true)
                                            setPayModalType('cat')
                                            triggerVibration('heavy');
                                            // }

                                        }}
                                        className={"lock-item-level-price"}
                                    >
                                        <img
                                            className="nav__icon"
                                            width="24"
                                            height="24"
                                            src={MonetaIcon}
                                            alt="arrow icon"
                                            style={{marginRight: 5, width: 13}}
                                        />
                                        +{userData?.next_cat_level?.price ? userData?.next_cat_level?.price : ''}
                                    </div>
                                    <div className={"lock-item-level"} style={{justifyContent: 'center'}}>
                                        Level Up
                                        {/*<Question3Icon/>*/}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"refferals-wrapper-bottom"}>
                            <div className={"lock-item"}>

                                <div className={"lock-item-icon"}>
                                    {/*<LockIcon/>*/}
                                    <img
                                        className=""
                                        src={clickerWrapper}
                                    />
                                    <img
                                        className="unlock-item-icon-position"
                                        src={lapka}
                                    />

                                </div>
                                <div className={"lock-item-name"}>
                                    {t('lapka')}
                                </div>

                                <div className={"lock-item-level"}>
                                    {userData?.next_attack_level?.num ? userData?.next_attack_level?.num : ''} {t('level_lowercase')}
                                    {/*<Question3Icon/>*/}
                                </div>


                                <div
                                    onClick={() => {
                                        setShowPayModal(true)
                                        setPayModalType('atack')
                                        triggerVibration('heavy');
                                    }}
                                    className={"lock-item-level-price"}
                                >
                                    <img
                                        className="nav__icon"
                                        width="24"
                                        height="24"
                                        src={MonetaIcon}
                                        alt="arrow icon"
                                        style={{marginRight: 5, width: 13}}
                                    />
                                    +{userData?.next_attack_level?.price ? userData?.next_attack_level?.price : ''}
                                </div>
                                <div className={'level-up-text'}>Level Up</div>

                            </div>
                            <div className={"lock-item"}>

                                <div className={"lock-item-icon"}>
                                    {/*<LockIcon/>*/}

                                    <img
                                        className=""
                                        src={clickerWrapper}
                                    />

                                    <IntelektIcon width={48} height={40} className="unlock-item-icon-position" />
                                </div>
                                <div className={"lock-item-name"}>
                                    {t('intelligent')}
                                </div>
                                <div className={"lock-item-level"}>
                                    {userData?.intelligence_level?.num > userData?.cat_level.num ?
                                        <span>{userData?.intelligence_level?.num} {t('level_lowercase')}</span>
                                        :
                                        <span>{userData?.next_intelligence_level?.num } {t('level_lowercase')}</span>
                                    }
                                    {/*<Question3Icon/>*/}
                                </div>

                                <div
                                    onClick={()=>{
                                        if (userData?.intelligence_level?.num != userData?.next_cat_level.num) {
                                            setShowPayModal(true)
                                            setPayModalType('intelekt')
                                            triggerVibration('heavy');
                                        }
                                    }}
                                    className={"lock-item-level-price"}
                                    style={{background: userData?.intelligence_level?.num > userData?.cat_level.num ? 'green' : '#F4D144'}}
                                >
                                    {userData?.intelligence_level?.num > userData?.cat_level.num ?

                                        <span style={{color: 'white'}}>{t('done')}</span>
                                        :
                                        <>
                                            <img
                                                className="nav__icon"
                                                width="24"
                                                height="24"
                                                src={MonetaIcon}
                                                alt="arrow icon"
                                                style={{marginRight: 5, width: 13}}
                                            />
                                            +{userData?.next_intelligence_level?.price ? userData?.next_intelligence_level?.price : ''}
                                        </>

                                    }

                                </div>

                                <div className={'level-up-text'}>
                                    {userData?.intelligence_level?.num <= userData?.cat_level.num ? 'Level Up' : ''}
                                </div>

                            </div>
                            <div className={"lock-item"}>

                            <div className={"lock-item-icon"}>
                                    {/*<LockIcon/>*/}

                                    <img
                                        className=""
                                        src={clickerWrapper}
                                    />

                                    <EnergyIcon2 width={46} height={46} className="unlock-item-icon-position"/>
                                </div>
                                <div className={"lock-item-name"}>
                                    {t('energy')}
                                </div>
                                <div className={"lock-item-level"}>
                                    {userData?.next_stamina_level?.num ? userData?.next_stamina_level?.num : ''} {t('level_lowercase')}
                                    {/*<Question3Icon/>*/}
                                </div>

                                <div
                                    onClick={() => {
                                        setShowPayModal(true)
                                        setPayModalType('stamina')
                                        triggerVibration('heavy');
                                    }}
                                    className={"lock-item-level-price"}
                                >
                                    <img
                                        className="nav__icon dd"
                                        width="10"
                                        height="10"
                                        src={MonetaIcon}
                                        alt="arrow icon"
                                        style={{marginRight: 5, width: 13}}
                                    />
                                    +{userData?.next_stamina_level?.price ? userData?.next_stamina_level?.price : ''}
                                </div>
                                <div className={'level-up-text'}>Level Up</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer page={'skills'}/>

            <PayModal
                coinBalance={coinBalance}
                newUserData={userData}
                isActive={showPayModal}
                onClose={(newData) => {
                    // setUserData(newData);
                    setShowPayModal(false)
                }}
                onPayClose={(newData) => {
                    setUserData(newData)
                    setShowCoinsAnimation(true)
                }}
                payModalType={payModalType}
            />

            {showCoinsAnimation &&
                <AnimatedCoins
                    stopCoinsAnimation={()=>{
                        setShowPayModal(false)
                        setShowCoinsAnimation(false)
                    }}
                />
            }
        </>

    );
}

export default Skills;

