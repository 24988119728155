// import React, {useEffect, useState} from 'react';
// import '../assets/css/header.css'
// import {
//     ChIcon,
//     DeIcon,
//     EnIcon,
//     EsIcon,
//     FrIcon,
//     ItIcon, KazakhIcon,
//     LngArrow,
//     PortIcon,
//     RuIcon, TurqIcon,
//     UkIcon,
//     UzIcon
// } from "./LanguageIcons";
// import {BrainIcon, EnergyIcon, LapkaIcon, Question1Icon} from "./icons";
// import LanguageModal from "./LanguageModal";
// import {useTelegram} from "../Context/TelegramContext";
// import {useLanguage} from "../Context/LanguageContext";
//
// import QuestionModal from "./QuestionModal";
// import CatDefault from "../assets/images/cat.png";
// import PayModal from "./PayModal";
// import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
// import MonetaIcon from "../assets/icons/monet.svg";
//
// const options = [
//     { value: 'en', label: 'English', icon: <EnIcon/> },
//     { value: 'es', label: 'Español', icon: <EsIcon/> },
//     { value: 'fr', label: 'Français', icon: <FrIcon/> },
//     { value: 'de', label: 'Deutsch', icon: <DeIcon/> },
//     { value: 'ch', label: '中文', icon: <ChIcon/> },
//     { value: 'it', label: 'Italiano', icon:<ItIcon/> },
//     { value: 'ru', label: 'Русский', icon: <RuIcon/> },
//     { value: 'pt', label: 'Português', icon:<PortIcon/> },
//     { value: 'uk', label: 'Ukraine', icon: <UkIcon/> },
//     { value: 'uz', label: 'Uzbekistan', icon: <UzIcon/>},
//     { value: 'kz', label: 'Kazakhstan', icon: <KazakhIcon/>},
//     { value: 'tr', label: 'Turkey', icon: <TurqIcon/>},
// ];
//
// function Header({userData,staminaMin,staminaMax,staminaLevel}) {
//
//     const navigate = useNavigate();
//     const { user, triggerVibration, userPhoto} = useTelegram();
//     const { t, language } = useLanguage();
//
//     const [selectedLanguage, setSelectedLanguage] = useState('ru');
//     const [isOpenLngModal, setIsOpenLngModal] = useState(false);
//     const [showQuestionModal, setShowQuestionModal] = useState(false);
//     const [payModalType, setPayModalType] = useState('');
//
//
//     // const [userData, setUserData] = useState(null);
//     // const [staminaMin, setStaminaMin] = useState('0');
//     // const [staminaMax, setStaminaMax] = useState('0');
//     // const [staminaLevel, setStaminaLevel] = useState('0');
//
//     const [isAnimating, setIsAnimating] = useState(false);
//
//
//     useEffect(() => {
//         console.log(selectedLanguage, 'selectedLanguage')
//     }, [selectedLanguage]);
//
//     useEffect(() => {
//
//         let lang = localStorage.getItem('language');
//         setSelectedLanguage(JSON.parse(lang))
//         console.log(userData, 'userData')
//         // console.log(userData?.lang, 'lang')
//
//     }, [userData]);
//
//     // useEffect(() => {
//     //     setIsAnimating(false);
//     //
//     //     const timer = setTimeout(() => {
//     //         setIsAnimating(true);
//     //     }, 10); // Анимация длится 3 секунды
//     //
//     //     return () => clearTimeout(timer);
//     // }, []);
//
//
//     function formatNumber(num) {
//         if (num < 1000) {
//             return num.toString();
//         }
//
//         const units = ["K", "M", "B", "T"];
//         const order = Math.floor(Math.log10(num) / 3);
//         const unitname = units[order - 1];
//         const scaled = num / Math.pow(1000, order);
//
//         // Округление до двух знаков после запятой
//         const rounded = Math.floor(scaled * 100) / 100;
//
//         return `+${rounded.toFixed(1).replace('.', '.')}${unitname}`;
//     }
//
//     function calculatePercentage(part, whole) {
//         if (whole === 0) {
//             return 0 // Округление до двух знаков после запятой
//             // throw new Error("The whole number cannot be zero.");
//         }
//         const percentage = (parseFloat(part) / parseFloat(whole)) * 100;
//         return percentage.toFixed(2); // Округление до двух знаков после запятой
//     }
//
//     return (
//         <header className={``}>
//
//             <div className={`top ${isAnimating ? '' : ''}`}>
//                 <div className="top-left">
//                     {userData?.cat_avatar &&
//                         <img
//                             className={"user-avatar"}
//                             src={userData?.cat_level?.avatar }
//                         />
//                     }
//
//                     <div className="top-left-texts">
//                         <p className="top-right-text1">
//                             {userData?.cat_name ? userData?.cat_name : ''} / <span style={{fontSize:12}}>{userData?.cat_level?.num} {t('level_uppercase')}</span>
//                         </p>
//                         <p className="top-right-text2" style={{display:'flex'}}>
//                             {userData?.cat_level?.league[`name_${language}`] || ''}
//                             <div
//                                 style={{marginLeft:5}}
//                                 onClick={() => {
//                                     setShowQuestionModal(true)
//                                     setPayModalType('user')
//                                 }}
//                             >
//                                 <Question1Icon/>
//                             </div>
//                         </p>
//                         {/*<p className="top-right-text2">*/}
//                         {/*    {userData?.cat_level?.league[`name_${userData?.lang}`] || ''}*/}
//                         {/*</p>*/}
//                     </div>
//
//                 </div>
//
//                 <div className="top-right">
//                     <div
//                         className="headerLanguageWrapper"
//                         onClick={() => {
//                             setIsOpenLngModal(true)
//                         }}
//                     >
//                         {/*<img src={RuIcon}/>*/}
//                         { options.find(option => option.value === selectedLanguage)?.icon}
//                         <LngArrow style={{marginLeft:8}}/>
//                     </div>
//                 </div>
//
//             </div>
//
//             <div className={`header-center ${isAnimating ? 'show' : ''}`}>
//
//                 <div className={'property-wrapper header-center-left'}>
//                     <div className={'property-wrapper-top'}>
//                         <BrainIcon/>
//
//                         <div className={'property-wrapper-top-text'}>
//                             {t('intelligent')}: <br/>
//                             <img
//                                 className="nav__icon"
//                                 width="15"
//                                 height="15"
//                                 src={MonetaIcon}
//                                 alt="arrow icon"
//                                 style={{
//                                     position:'relative',
//                                     top:2,
//                                     marginRight:1
//                                 }}
//
//                             />  {userData?.intelligence_level?.bonus ? formatNumber(userData?.intelligence_level?.bonus) : '+0'}
//                             {/*{t('monet_per_hour')}*/}
//                         </div>
//                         <div
//                             onClick={() => {
//                                 setShowQuestionModal(true)
//                                 setPayModalType('intelekt')
//                             }}
//                         >
//                         <Question1Icon/>
//                         </div>
//                     </div>
//                     <div className={'property-wrapper-bottom'}>
//                         {userData?.intelligence_level?.num ? userData?.intelligence_level?.num : ''} {t('level_lowercase')}
//                     </div>
//                 </div>
//
//                 <div className={'property-wrapper'}>
//                     <div className={'property-wrapper-top'}>
//                         <LapkaIcon/>
//
//                         <div className={'property-wrapper-top-text'}>
//                             {t('lapka')}: <br/>
//                             <img
//                                 className="nav__icon"
//                                 width="15"
//                                 height="15"
//                                 src={MonetaIcon}
//                                 alt="arrow icon"
//                                 style={{
//                                     position: 'relative',
//                                     top: 2,
//                                     marginRight:5
//                                 }}
//
//                             />
//                             + {userData?.attack_level?.bonus ? userData?.attack_level?.bonus : '0'}
//                             {/*{t('for_tap')}*/}
//                         </div>
//                         <div
//                             onClick={() => {
//                                 setShowQuestionModal(true);
//                                 setPayModalType('atack')
//                             }}
//                         >
//                             <Question1Icon/>
//
//                         </div>
//                     </div>
//                     <div className={'property-wrapper-bottom'}>
//                         {userData?.attack_level?.num ? userData?.attack_level?.num : ''} {t('level_lowercase')}
//                     </div>
//                 </div>
//             </div>
//
//             <div className={`energy-block-main-wrapper ${isAnimating ? 'show' : ''}`}>
//                 <div className={'energy-block-wrapper'}>
//                     <EnergyIcon/>
//                     <div className={'energy-block-wrapper-title'}>{t('energy')}:</div>
//
//                     <div className={'energy-progress'}>
//                         <div className={'energy-active-progress'}
//                              style={{width: `${calculatePercentage(staminaMin, staminaMax)}%`}}>
//                         </div>
//                         <div className={'energy-progress-value'}>
//                             {staminaMin} / {staminaMax}
//                         </div>
//                     </div>
//                 </div>
//
//                 <div className={'energy-bottom'}>
//                     <div className={'energy-bottom-left'}>
//                         {staminaLevel ? staminaLevel : ''} {t('level_lowercase')}
//                     </div>
//
//                     <div className={'energy-bottom-right'}>
//                         <div
//                             onClick={() => {
//                                 setShowQuestionModal(true)
//                                 setPayModalType('stamina')
//                                 triggerVibration('heavy');
//                             }}
//                         >
//                             <Question1Icon/>
//
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//
//             {isOpenLngModal &&
//                 <LanguageModal
//                     setSelectedLanguage={setSelectedLanguage}
//                     selectedLanguage={selectedLanguage}
//                     isOpen={isOpenLngModal}
//                     onclose={() => {
//                         setIsOpenLngModal(false)
//                     }}
//                 />
//             }
//
//
//             {showQuestionModal &&
//                 <QuestionModal
//                     isActive={showQuestionModal}
//                     onClose={() => {
//                         setShowQuestionModal(false)
//                     }}
//                     onCloseWithRedirect={() => {
//                         setShowQuestionModal(false)
//                         navigate('/skills')
//                     }}
//                     userData={userData}
//                     payModalType={payModalType}
//                 />
//             }
//
//
//         </header>
//     );
// }
//
// export default Header;


import React, { useEffect, useState, useCallback, useMemo } from 'react';
import '../assets/css/header.css';
import {
    ChIcon, DeIcon, EnIcon, EsIcon, FrIcon, ItIcon, KazakhIcon,
    LngArrow, PortIcon, RuIcon, TurqIcon, UkIcon, UzIcon
} from "./LanguageIcons";
import { BrainIcon, EnergyIcon, LapkaIcon, Question1Icon } from "./icons";
import LanguageModal from "./LanguageModal";
import { useTelegram } from "../Context/TelegramContext";
import { useLanguage } from "../Context/LanguageContext";
import QuestionModal from "./QuestionModal";
import PayModal from "./PayModal";
import { useNavigate } from 'react-router-dom';
import MonetaIcon from "../assets/icons/monet.svg";

const options = [
    { value: 'en', label: 'English', icon: <EnIcon /> },
    { value: 'es', label: 'Español', icon: <EsIcon /> },
    { value: 'fr', label: 'Français', icon: <FrIcon /> },
    { value: 'de', label: 'Deutsch', icon: <DeIcon /> },
    { value: 'ch', label: '中文', icon: <ChIcon /> },
    { value: 'it', label: 'Italiano', icon: <ItIcon /> },
    { value: 'ru', label: 'Русский', icon: <RuIcon /> },
    { value: 'pt', label: 'Português', icon: <PortIcon /> },
    { value: 'uk', label: 'Ukraine', icon: <UkIcon /> },
    { value: 'uz', label: 'Uzbekistan', icon: <UzIcon /> },
    { value: 'kz', label: 'Kazakhstan', icon: <KazakhIcon /> },
    { value: 'tr', label: 'Turkey', icon: <TurqIcon /> },
];

const Header = React.memo(({ userData, staminaMin, staminaMax, staminaLevel }) => {
    const navigate = useNavigate();
    const { user, triggerVibration, userPhoto } = useTelegram();
    const { t, language } = useLanguage();

    const [selectedLanguage, setSelectedLanguage] = useState('ru');
    const [isOpenLngModal, setIsOpenLngModal] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [payModalType, setPayModalType] = useState('');

    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        console.log(selectedLanguage, 'selectedLanguage');
    }, [selectedLanguage]);

    useEffect(() => {
        let lang = localStorage.getItem('language');
        if (lang) {
            setSelectedLanguage(JSON.parse(lang));
        }
        // console.log(userData, 'userData');
    }, [userData]);

    const formatNumber = useCallback((num) => {
        if (num < 1000) {
            return num.toString();
        }

        const units = ["K", "M", "B", "T"];
        const order = Math.floor(Math.log10(num) / 3);
        const unitname = units[order - 1];
        const scaled = num / Math.pow(1000, order);

        const rounded = Math.floor(scaled * 100) / 100;

        return `+${rounded.toFixed(1).replace('.', '.')}${unitname}`;
    }, []);

    const calculatePercentage = useCallback((part, whole) => {
        if (whole === 0) {
            return 0;
        }
        const percentage = (parseFloat(part) / parseFloat(whole)) * 100;
        return percentage.toFixed(2);
    }, []);

    const handleOpenLanguageModal = useCallback(() => {
        setIsOpenLngModal(true);
    }, []);

    const handleOpenQuestionModal = useCallback((type) => {
        setShowQuestionModal(true);
        setPayModalType(type);
    }, []);

    const selectedLanguageIcon = useMemo(() => {
        return options.find(option => option.value === selectedLanguage)?.icon;
    }, [selectedLanguage]);

    return (
        <header>
            <div className={`top ${isAnimating ? '' : ''}`}>
                <div className="top-left">
                    {userData?.cat_avatar &&
                        <img
                            className={"user-avatar"}
                            src={userData?.cat_level?.avatar}
                        />
                    }

                    <div className="top-left-texts">
                        <p className="top-right-text1">
                            <span className={'top-right-text1-span1'}>{userData?.cat_name ? userData?.cat_name : ''}</span>
                            <span className={'top-right-text1-span2'}>/</span>
                            <span className={'top-right-text1-span3'} >{userData?.cat_level?.num} {t('level_uppercase')}</span>
                        </p>
                        <p className="top-right-text2" style={{ display: 'flex' }}>
                            {userData?.cat_level?.league[`name_${language}`] || ''}
                            <div
                                style={{ marginLeft: 5 }}
                                onClick={() => handleOpenQuestionModal('user')}
                            >
                                <Question1Icon />
                            </div>
                        </p>
                    </div>
                </div>

                <div className="top-right">
                    <div
                        className="headerLanguageWrapper"
                        onClick={handleOpenLanguageModal}
                    >
                        {selectedLanguageIcon}
                        <LngArrow style={{ marginLeft: 8 }} />
                    </div>
                </div>
            </div>

            <div className={`header-center ${isAnimating ? 'show' : ''}`}>
                <div className={'property-wrapper header-center-left'}>
                    <div className={'property-wrapper-top'}>
                        <BrainIcon />

                        <div className={'property-wrapper-top-text'}>
                            {t('intelligent')}: <br />
                            <img
                                className="nav__icon"
                                width="15"
                                height="15"
                                src={MonetaIcon}
                                alt="arrow icon"
                                style={{
                                    position: 'relative',
                                    top: 2,
                                    marginRight: 1
                                }}
                            />  {userData?.intelligence_level?.bonus ? formatNumber(userData?.intelligence_level?.bonus) : '+0'}
                        </div>
                        <div
                            onClick={() => handleOpenQuestionModal('intelekt')}
                        >
                            <Question1Icon />
                        </div>
                    </div>
                    <div className={'property-wrapper-bottom'}>
                        {userData?.intelligence_level?.num ? userData?.intelligence_level?.num : ''} {t('level_lowercase')}
                    </div>
                </div>

                <div className={'property-wrapper'}>
                    <div className={'property-wrapper-top'}>
                        <LapkaIcon />

                        <div className={'property-wrapper-top-text'}>
                            {t('lapka')}: <br />
                            <img
                                className="nav__icon"
                                width="15"
                                height="15"
                                src={MonetaIcon}
                                alt="arrow icon"
                                style={{
                                    position: 'relative',
                                    top: 2,
                                    marginRight: 5
                                }}
                            />
                            + {userData?.attack_level?.bonus ? userData?.attack_level?.bonus : '0'}
                        </div>
                        <div
                            onClick={() => handleOpenQuestionModal('atack')}
                        >
                            <Question1Icon />
                        </div>
                    </div>
                    <div className={'property-wrapper-bottom'}>
                        {userData?.attack_level?.num ? userData?.attack_level?.num : ''} {t('level_lowercase')}
                    </div>
                </div>
            </div>

            <div className={`energy-block-main-wrapper ${isAnimating ? 'show' : ''}`}>
                <div className={'energy-block-wrapper'}>
                    <EnergyIcon />
                    <div className={'energy-block-wrapper-title'}>{t('energy')}:</div>

                    <div className={'energy-progress'}>
                        <div className={'energy-active-progress'}
                             style={{ width: `${calculatePercentage(staminaMin, staminaMax)}%` }}>
                        </div>
                        <div className={'energy-progress-value'}>
                            {parseInt(staminaMin)} / {staminaMax}
                        </div>
                    </div>
                </div>

                <div className={'energy-bottom'}>
                    <div className={'energy-bottom-left'}>
                        {staminaLevel ? staminaLevel : ''} {t('level_lowercase')}
                    </div>

                    <div className={'energy-bottom-right'}>
                        <div
                            onClick={() => {
                                handleOpenQuestionModal('stamina');
                                triggerVibration('heavy');
                            }}
                        >
                            <Question1Icon />
                        </div>
                    </div>
                </div>
            </div>

            {isOpenLngModal &&
                <LanguageModal
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    isOpen={isOpenLngModal}
                    onclose={() => setIsOpenLngModal(false)}
                />
            }

            {showQuestionModal &&
                <QuestionModal
                    isActive={showQuestionModal}
                    onClose={() => setShowQuestionModal(false)}
                    onCloseWithRedirect={() => {
                        setShowQuestionModal(false);
                        navigate('/skills');
                    }}
                    userData={userData}
                    payModalType={payModalType}
                />
            }
        </header>
    );
});

export default Header;
