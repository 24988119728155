import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/farm.css'
import Footer from "../../components/Footer";
import calendar from '../../assets/images/calendar2.png'
import purpleShadow from '../../assets/images/purpleShadow.png'
import clickerWrapper from '../../assets/images/clickerWrapper.png'
import AnimatedNumber from "../../components/AnimatedNumber";
import Header from "../../components/Header";
import {
    CloseIcon,
    EnergyIcon2,
    IntelektIcon,
    LapkaIcon,
    GreenIcon,
    RedCloseIcon
} from "../../components/icons";

import MonetaIcon from '../../assets/icons/monet.svg'
import {useLanguage} from "../../Context/LanguageContext";
import {stringToBase64} from "../../utils/format";
import {logEvent} from "../../utils/logger";


function PayModal({isActive, onClose, onPayClose, newUserData, payModalType, coinBalance}) {

    const { user, triggerVibration, webApp } = useTelegram();
    const DOMAIN = process.env.REACT_APP_DOMAIN;
    const { t, language } = useLanguage();

    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const [requestInProgress, setRequestInProgress] = useState(false);

    const [showAnimation, setShowAnimation] = useState(true);
    const [userData, setUserData] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(()=>{
        setShowAnimation(false);
        setUserData(newUserData);
    }, [isActive]);

    const handlePay = async (price) => {

        if (requestInProgress) {
            return;
        } else {
            setRequestInProgress(true);
        }

        setShowAnimation(true)

        let field = ''
        if (payModalType == 'cat') {
            field = 'cat_level'
        } else if(payModalType == 'stamina') {
            field = 'stamina_level'
        } else if(payModalType == 'intelekt') {
            field = 'intelligence_level'
        } else if(payModalType == 'atack') {
            field = 'attack_level'
        }

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/level-up/${field}`, {
            method: 'GET',
            headers: {
                "X-UserId": init_data
            }
        });

        const statusCode = response.status;

        if (response.ok) {

            const data = await response.json();

            if (statusCode === 200 || statusCode === 201) {

                console.log('Success:', statusCode,  data);

                let coin_balance = localStorage.getItem('coin_balance');
                coin_balance = JSON.parse(coin_balance)


                console.log(coin_balance, 'coin_balance')
                console.log(price, 'price')

                localStorage.setItem('user', JSON.stringify(data));
                localStorage.setItem('coin_minus', price);

                if (field == 'stamina_level') {
                    localStorage.setItem('stamina_min', JSON.stringify(data?.stamina_level?.bonus));
                    localStorage.setItem('stamina_max', JSON.stringify(data?.stamina_level?.bonus));
                }

                localStorage.setItem('stamina_level', JSON.stringify(data?.stamina_level?.num));

                logEvent('info', `Upgrade ${payModalType}`, {
                    stamina_level: data?.stamina_level?.num,
                    old_coin_balance: parseFloat(coin_balance),
                    action: 'Upgrade'
                });

                setTimeout(()=>{
                    setRequestInProgress(false);
                    onPayClose(data);
                }, 500);

            }
        } else {
            logEvent('info', `Upgrade ${payModalType} ERROR`, {
                stamina_level: '',
                old_coin_balance: '',
                new_coin_balance: '',
                action: 'Upgrade'
            });
            setRequestInProgress(false);
            onClose()
        }


    }


    const RenderIcon = () => {

        if (payModalType == 'cat') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <img
                        className="pay-modal-icon-cat"
                        src={userData?.next_cat_level?.avatar}
                    />
                </div>
        )
        } else if (payModalType == 'stamina') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                   <EnergyIcon2 width={90} height={90}/>
               </div>
            )
        } else if (payModalType == 'intelekt') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <IntelektIcon width={90} height={90} style={{marginRight:5}}/>
                </div>

            )
        } else if (payModalType == 'atack') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <LapkaIcon width={90} height={90} style={{marginRight:5}}/>
                </div>

            )
        }

    }

    const RenderDescription = () => {

        if (payModalType == 'cat') {
            return (
                <>
                    <h1 className={'question-title'} style={{marginBottom: 5}}> {t('crypto_broker')}</h1>
                    <p className={'question-sibtitle'} style={{margin: 0}}>
                        {userData?.next_cat_level?.num} {t('level_lowercase')}
                    </p>
                    <p className={'question-sibtitle'}>
                        {t('cat_desc2')}
                    </p>
                </>
            )
        } else if (payModalType == 'stamina') {
            return (
                <>
                    <h1 className={'question-title'} style={{marginBottom: 5}}>{t('energy')}</h1>
                    <p className={'question-sibtitle'} style={{margin: 0}}>
                        {userData?.next_stamina_level?.num} {t('level_lowercase')}
                    </p>
                    <p className={'question-sibtitle'}>
                        {t('energy_desc2')}
                    </p>
                </>

            )
        } else if (payModalType == 'intelekt') {
            return (
                <>
                    <h1 className={'question-title'} style={{marginBottom: 5}}>{t('intelligent')}</h1>

                    <p className={'question-sibtitle'} style={{margin: 0}}>
                        {userData?.next_intelligence_level?.num} {t('level_lowercase')}
                    </p>
                    <p className={'question-sibtitle'}>
                        {t('intelligent_desc2')}
                    </p>
                </>
            )
        } else if (payModalType == 'atack') {
            return (
                <>
                    <h1 className={'question-title'} style={{marginBottom: 5}}>{t('lapka')}</h1>

                    <p className={'question-sibtitle'} style={{margin: 0}}>
                        {userData?.next_attack_level?.num} {t('level_lowercase')}
                    </p>
                    <p className={'question-sibtitle'}>
                        {t('lapka_desс2')}
                    </p>
                </>

            )
        }

    }

    const RenderAddedData = () => {

        if (payModalType == 'cat') {
            return (
                <div className={'render-added-wrapper'}>
                    {/*{t('update_all')}*/}
                </div>
            )
        } else if (payModalType == 'stamina') {
            return (
               <div className={'render-added-wrapper'}>
                   <EnergyIcon2 width={25} height={25}/>
                   +{userData?.next_stamina_level?.bonus } {t('energy2')}
               </div>
            )
        } else if (payModalType == 'intelekt') {
            return (
                <div className={'render-added-wrapper'}>
                    <IntelektIcon width={25} height={25} style={{marginRight:5}}/>
                    +{userData?.next_intelligence_level?.bonus }
                </div>
            )
        } else if (payModalType == 'atack') {
            return (
               <div className={'render-added-wrapper'}>
                   <LapkaIcon width={20} height={20} style={{marginRight:5}}/>
                   + {userData?.next_attack_level?.bonus}
               </div>
            )
        }

    }


    const checkbalance = (price) => {

        console.log(coinBalance , price, coinBalance >= price, '------')
        if (coinBalance >= price ) {
            return true
        } else {
            return false
        }
    }


    function getFriendWord(number) {
        const remainder10 = number % 10;
        const remainder100 = number % 100;

        if (remainder100 >= 11 && remainder100 <= 19) {
            return `${number} ${t('friends_holow1')}`;
        } else if (remainder10 === 1) {
            return `${number} ${t('friends_holow2')}`;
        } else if (remainder10 >= 2 && remainder10 <= 4) {
            return `${number} ${t('friends_holow3')}`;
        } else {
            return `${number} ${t('friends_holow1')}`;
        }
    }

    const RenderPrice = () => {

        if (payModalType == 'cat') {

            let checkbalance_ = checkbalance(userData?.next_cat_level?.price);
            let checkAvalaible = checkBtnAvalaible(checkbalance_, userData?.next_cat_level?.required_referreds, userData?.referreds_num);

            if (userData?.intelligence_level?.num <= userData?.cat_level.num) {
                checkAvalaible = false;
            }

            if (requestInProgress) {
                checkAvalaible = false;
            }

            return (
                <>
                    <div className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 10}}

                        />
                        {userData?.next_cat_level?.price}
                    </div>

                    {userData?.next_cat_level?.required_referreds > 0 &&
                        <>
                            {userData?.next_cat_level?.required_referreds > 0 && userData?.referreds_num < userData?.next_cat_level?.required_referreds ?

                                <div className={'required-referreds'} style={{marginBottom:0}}>
                                    <div style={{width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <RedCloseIcon/>
                                    </div>
                                    {t('invite_more')}  {getFriendWord(userData?.next_cat_level?.required_referreds - userData?.referreds_num)}
                                </div>
                                :
                                <div className={'required-referreds'}>
                                    <GreenIcon/> {t('conditions_met')}
                                </div>
                            }

                        </>
                    }


                    {userData?.intelligence_level?.num <= userData?.cat_level.num ?

                        <div className={'required-intelekt-level'}>
                            <div style={{
                                width: 48,
                                height: 48,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <RedCloseIcon/>
                            </div>
                            {t('increase_your_intelligence_to')} {userData?.next_intelligence_level.num} {t('level_lowercase2')}
                        </div>
                        :
                        <div className={'required-referreds'}>
                            <GreenIcon/> {t('conditions_met')}
                        </div>
                    }


                    <div style={{flex: 1, padding: '0 25'}}>
                        <div
                            style={{opacity: checkAvalaible ? 1 : 0.5}}
                            onClick={() => {
                                triggerVibration('heavy');
                                if (checkAvalaible) {
                                    handlePay(userData?.next_cat_level?.price)
                                }
                            }}
                            className={'task-wrapper-right2'}
                        >

                            <p className={'task-text12'}>
                                {showAnimation ?
                                    <>
                                        <div className="dots-container">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {getBtnText(checkbalance_, userData?.next_cat_level?.required_referreds, userData?.referreds_num)}
                                    </>

                                }
                            </p>
                        </div>
                    </div>
                </>
            )
        } else if (payModalType == 'stamina') {

            let checkbalance_ = checkbalance(userData?.next_stamina_level?.price);
            let checkAvalaible = checkBtnAvalaible(checkbalance_, userData?.next_stamina_level?.required_referreds, userData?.referreds_num);

            if (userData?.next_stamina_level?.cat_level_required > 0 && userData?.next_stamina_level?.cat_level_required > userData?.cat_level?.num) {
                checkAvalaible = false
            }

            return (
                <>
                    <div className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 10}}
                        />
                        {userData?.next_stamina_level?.price}
                    </div>


                    {userData?.next_stamina_level?.required_referreds > 0 &&
                        <>

                            {userData?.next_stamina_level?.required_referreds > 0 &&
                                <>
                                    {userData?.referreds_num < userData?.next_stamina_level?.required_referreds ?
                                        <div className={'required-referreds'}>
                                            <div style={{
                                                width: 48,
                                                height: 48,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <RedCloseIcon/>
                                            </div>
                                            {t('invite_more')} {getFriendWord(userData?.next_stamina_level?.required_referreds - userData?.referreds_num)}
                                        </div>
                                        :
                                        <div className={'required-referreds'}>
                                            <GreenIcon/> {t('conditions_met')}
                                        </div>
                                    }
                                </>
                            }

                        </>
                    }


                    {userData?.next_stamina_level?.cat_level_required > 0 &&
                        <>
                            {userData?.next_stamina_level?.cat_level_required > userData?.cat_level?.num ?

                                <div className={'required-referreds'}>
                                    <div style={{
                                        width: 48,
                                        height: 48,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <RedCloseIcon/>
                                    </div>
                                    {t('required_cat_level')} {userData?.next_stamina_level?.cat_level_required}
                                </div>
                                :
                                <div className={'required-referreds'}>
                                    <GreenIcon/> {t('conditions_met')}
                                </div>
                            }
                        </>
                    }


                    <div style={{flex: 1, padding: '0 25'}}>
                        <div
                            style={{opacity: checkAvalaible ? 1 : 0.5}}
                            onClick={() => {
                                triggerVibration('heavy');
                                if (checkAvalaible) {
                                    handlePay(userData?.next_stamina_level?.price)
                                }
                            }}
                            className={'task-wrapper-right2'}
                        >
                            <p className={'task-text12'}>
                                {showAnimation ?
                                    <>
                                        <div className="dots-container">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {getBtnText(checkbalance_, userData?.next_stamina_level?.required_referreds, userData?.referreds_num)}
                                    </>
                                }
                            </p>
                        </div>
                    </div>
                </>
            )

        } else if (payModalType == 'intelekt') {

            let checkbalance_ = checkbalance(userData?.next_intelligence_level?.price);
            let checkAvalaible = checkBtnAvalaible(checkbalance_, userData?.next_intelligence_level?.required_referreds, userData?.referreds_num);


            return (
                <>
                    <div className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 10}}
                        />
                        {userData?.next_intelligence_level?.price}
                    </div>


                    {userData?.next_intelligence_level?.required_referreds > 0 &&
                        <>
                            {userData?.referreds_num < userData?.next_intelligence_level?.required_referreds ?
                                <div className={'required-referreds'}>
                                    <div style={{
                                        width: 48,
                                        height: 48,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <RedCloseIcon/>
                                    </div>
                                    {t('invite_more')} +{getFriendWord(userData?.next_intelligence_level?.required_referreds - userData?.referreds_num)}
                                </div>
                                :
                                <div className={'required-referreds'}>
                                    <GreenIcon/> {t('conditions_met')}
                                </div>
                            }
                        </>
                    }


                    <div style={{flex: 1, padding: '0 25'}}>
                        <div
                            style={{opacity: checkAvalaible ? 1 : 0.5}}
                            onClick={() => {
                                triggerVibration('heavy');
                                if (checkAvalaible) {
                                    handlePay(userData?.next_intelligence_level?.price)
                                }
                            }}
                            className={'task-wrapper-right2'}
                        >
                            <p className={'task-text12'}>

                                {showAnimation ?
                                    <>
                                        <div className="dots-container">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {getBtnText(checkbalance_, userData?.next_intelligence_level?.required_referreds, userData?.referreds_num)}
                                    </>
                                }

                            </p>
                        </div>
                    </div>
                </>
            )
        }
        else if (payModalType == 'atack') {

            let checkbalance_ = checkbalance(userData?.next_attack_level?.price);
            let checkAvalaible = checkBtnAvalaible(checkbalance_, userData?.next_attack_level?.required_referreds, userData?.referreds_num);

            if (userData?.next_attack_level?.cat_level_required > 0 && userData?.next_attack_level?.cat_level_required > userData?.cat_level?.num) {
                checkAvalaible = false
            }

            return (
                <>
                    <div className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 10}}

                        />
                        {userData?.next_attack_level?.price}
                    </div>

                    {userData?.next_attack_level?.required_referreds > 0 &&
                        <>
                            {userData?.next_attack_level?.required_referreds > 0 &&
                                <>
                                    {userData?.referreds_num < userData?.next_attack_level?.required_referreds ?

                                        <div className={'required-referreds'}>
                                            <div style={{
                                                width: 48,
                                                height: 48,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <RedCloseIcon/>
                                            </div>
                                            {t('invite_more')} {getFriendWord(userData?.next_attack_level?.required_referreds - userData?.referreds_num)}
                                        </div>
                                        :
                                        <div className={'required-referreds'}>
                                            <GreenIcon/> {t('conditions_met')}
                                        </div>
                                    }
                                </>
                            }

                        </>
                    }



                    {userData?.next_attack_level?.cat_level_required > 0 &&
                        <>
                            {userData?.next_attack_level?.cat_level_required > userData?.cat_level?.num ?
                                <div className={'required-referreds'}>
                                    <div style={{
                                        width: 48,
                                        height: 48,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <RedCloseIcon/>
                                    </div>
                                    {t('required_crypto_broker_level')} {userData?.next_attack_level?.cat_level_required}
                                </div>
                                :
                                <div className={'required-referreds'}>
                                    <GreenIcon/> {t('conditions_met')}
                                </div>
                            }
                        </>
                    }


                    <div style={{flex: 1, padding: '0 25'}}>
                        <div style={{opacity: checkAvalaible ? 1 : 0.5}}
                            onClick={() => {
                                triggerVibration('heavy');
                                if (checkAvalaible) {
                                    handlePay(userData?.next_attack_level?.price)
                                }
                            }}
                            className={'task-wrapper-right2'}
                        >
                            <p className={'task-text12'}>

                                {showAnimation ?
                                    <>
                                        <div className="dots-container">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {getBtnText(checkbalance_, userData?.next_attack_level?.required_referreds, userData?.referreds_num)}
                                    </>
                                }

                            </p>
                        </div>
                    </div>
                </>

            )
        }

    }

    const getBtnText = (checkbalance_, required_referreds, referreds_num) => {

        let get_ = t('get')
        if (checkbalance_) {
            if (required_referreds > 0) {

                if (referreds_num < required_referreds) {
                    return `${t('invite_more')} ${required_referreds - referreds_num} ${t('friends_holow3')}`
                } else {
                    return get_
                }
            } else {
                return get_
            }

        } else {
            return get_
        }

    }

    const checkBtnAvalaible = (checkbalance_, required_referreds, referreds_num) => {

        if (checkbalance_) {
            if (required_referreds > 0) {

                if (referreds_num < required_referreds) {
                    return false
                } else {
                    return true
                }
            } else {
                return  true
            }
        } else {

            return false;

        }
    }



    return (
        <div className={`question-reward-wrapper ${isActive ? 'open' : ''}`} >


            <div className={'question-reward'}>

                <img className="questionpurpleShadow" src={purpleShadow}/>

                <div
                    onClick={() => {
                        onClose()
                    }}
                    className={'close-icon'}
                >
                    <CloseIcon/>
                </div>


                <RenderIcon/>
                <RenderDescription/>
                <RenderAddedData/>
                <RenderPrice/>
            </div>
        </div>
    );
}

export default PayModal;

