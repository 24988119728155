import {useState, useEffect, useRef} from 'react';
import {useTelegram} from "../Context/TelegramContext/index";
import {stringToBase64} from "../utils/format";

const useCheckSocNetwork = () => {

    const DOMAIN = process.env.REACT_APP_DOMAIN;
    const { user, triggerVibration, webApp  } = useTelegram();

    const [subscribeData, setSubscribeData] = useState(null);
    const [loadingSubscribeData, setLoadingSubscribeData] = useState(true);
    const [errorSubscribeData, setErrorSubscribeData] = useState(null);

    const isMounted = useRef(false);

    useEffect(() => {

        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                fetchCheckSocNetwork();
                isMounted.current = true;
            }
        } else {

            if (webApp?.initData && webApp?.initData != '') {
                fetchCheckSocNetwork()
            }
        }


    }, [webApp?.initData]);

    const fetchCheckSocNetwork = async () => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/social-networks/`, {
            method: 'GET',
            headers: {
                "X-UserId": init_data
            }
        });

        const statusCode = response.status;

        if (response.ok) {
            const data = await response.json();
            if (statusCode === 200 || statusCode === 201) {
                setSubscribeData(data);
                setLoadingSubscribeData(false);
                setErrorSubscribeData(false);

            }
        } else {
            setErrorSubscribeData(true);
            setLoadingSubscribeData(false);
            if (statusCode === 403) {
                // navigate('/banned')
            } else if (statusCode === 401) {
                // navigate('/login')
            }
        }


    };


    return {subscribeData,  loadingSubscribeData, errorSubscribeData, fetchCheckSocNetwork };
};

export default useCheckSocNetwork;
