import React, {useEffect, useState, useRef} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/language_modal.css'
import {CloseIcon} from "../../components/icons";
import { useLanguage } from '../../Context/LanguageContext';

import {
    ChIcon,
    DeIcon,
    EnIcon,
    EsIcon,
    FrIcon,
    ItIcon,
    KazakhIcon, LngArrow,
    PortIcon,
    RuIcon, TurqIcon,
    UkIcon,
    UzIcon
} from "../LanguageIcons";
import {stringToBase64} from "../../utils/format";

function LanguageModal({setSelectedLanguage, selectedLanguage, isOpen, onclose}) {
    const { user, webApp } = useTelegram();
    const DOMAIN = process.env.REACT_APP_DOMAIN;
    const { setLanguage } = useLanguage();

    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { value: 'en', label: 'English', icon: <EnIcon/> },
        { value: 'es', label: 'Español', icon: <EsIcon/> },
        { value: 'fr', label: 'Français', icon: <FrIcon/> },
        { value: 'de', label: 'Deutsch', icon: <DeIcon/> },
        { value: 'ch', label: '中文', icon: <ChIcon/> },
        { value: 'it', label: 'Italiano', icon:<ItIcon/> },
        { value: 'ru', label: 'Русский', icon: <RuIcon/> },
        { value: 'pt', label: 'Português', icon:<PortIcon/> },
        { value: 'uk', label: 'Ukraine', icon: <UkIcon/> },
        { value: 'uz', label: 'Uzbekistan', icon: <UzIcon/>},
        { value: 'kz', label: 'Kazakhstan', icon: <KazakhIcon/>},
        { value: 'tr', label: 'Turkey', icon: <TurqIcon/>},
    ];

    const handleSelectChange = async (option) =>
    {
        setSelectedOption(option);
        setSelectedLanguage(option.value);
        setLanguage(option.value);

        localStorage.setItem('language', JSON.stringify(option.value));

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/me/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json', // Указываем, что отправляем данные в формате JSON
                'Accept': 'application/json',
                "X-UserId": init_data
            },
            body: JSON.stringify({
                lang: option.value
            })
        });

        const statusCode = response.status;

        if (response.ok) {

            const data = await response.json();
            console.log(data, 'data')

            onclose()

        } else {
            if (statusCode === 403) {
                // navigate('/banned')
            } else if (statusCode === 401) {
                // navigate('/login')
            }
        }

    };


    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div className={'language-modal'} style={{}}>

                <div
                    className={'close-icon'}
                    onClick={()=>{
                        onclose()
                    }}
                >
                    <CloseIcon/>
                </div>

                <h1 className={'title'}>Выберите язык кота:</h1>

                <CustomSelect
                    selectedLanguage={selectedLanguage}
                    options={options}
                    onChange={handleSelectChange}
                    placeholder="Select an option"
                />

            </div>

        </>

    );
}


const CustomSelect = ({options, onChange, placeholder, selectedLanguage}) => {
    const [isOpen, setIsOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState( { value: 'ru', label: 'Русский', icon: <RuIcon/> });
    const dropdownRef = useRef(null);

    useEffect(()=>{

        setSelectedOption(options.find(option => option.value === selectedLanguage))

    }, [selectedLanguage])

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onChange) {
            onChange(option);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-select-container" ref={dropdownRef}>
            <div className="custom-select-header" onClick={handleToggleDropdown}>

                <div className={'custom-select-header-top'}>
                    {selectedOption && selectedOption.icon }
                    <span style={{marginLeft:10}}>{selectedOption ? selectedOption.label : placeholder}</span>
                </div>

                <span className={`arrow ${isOpen ? 'open' : ''}`}>
                    {/*&#9660;*/}
                    <LngArrow/>
                </span>
            </div>
            {isOpen && (
                <ul className="custom-select-options">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className="custom-select-option"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.icon}
                            <span style={{marginLeft:10}}>{option.label}</span>

                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
export default LanguageModal;

