import React, { useEffect, useRef } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTelegram } from './Context/TelegramContext/index';
import {TonConnectUIProvider} from '@tonconnect/ui-react';

import './assets/css/loader.css';

import LoaderImg from './assets/images/1221212.png';
import CatsImg from './assets/images/loader/cats.png';
import CatstonImg from './assets/images/loader/catston.png';

import Tgicon from './assets/images/loader/tg.png';
import YtIcon from './assets/images/loader/yt.png';
import XIcon from './assets/images/loader/x1.png';

import Farm from './pages/Farm';
import Skills from './pages/Skills';
import Refferals from './pages/Refferals';
import Tasks from './pages/Tasks';
import Airdrop from './pages/Airdrop';
import QrPage from './pages/QrPage';
import Banned from "./pages/GuestPages/Banned";
import Error from "./pages/GuestPages/Error";
import Login from "./pages/GuestPages/Login";
import DailyReward from "./pages/DailyReward";

import Location from "./pages/Location";
import ShoppingMall from "./pages/Location/ShoppingMall";
import City from "./pages/Location/City";
import Birzha from "./pages/Location/Birzha";

import Loader from "react-js-loader";
import {stringToBase64} from "./utils/format";
import {clearLogs, logEvent, sendLogInDb} from "./utils/logger";
function App() {
    const { user, webApp } = useTelegram();
    const DOMAIN = process.env.REACT_APP_DOMAIN;
    const WEB_SOCKET_DOMAIN = process.env.REACT_APP_WEB_SOCKET_DOMAIN;

    const [isLogin, setIsLogin] = React.useState(false);
    const [banned, setBanned] = React.useState(false);
    const [loader, setLoader] = React.useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const [connectSocket, setConnectSocket] = React.useState(false);
    const [socket, setSocket] = React.useState(null);

    const isMounted = useRef(false);

    useEffect(() => {

        // DEV
        if (process.env.REACT_APP_DEBUG == 'true') {
            if (!isMounted.current) {
                console.log(user, 'user------')
                checkUser();
                isMounted.current = true;
            }
        } else {
            // PROD
            if (webApp?.initData && webApp?.initData != '') {

                if (getDeviceType() == 'desktop') {
                    setLoader(false)
                    navigate('/qr')
                } else {
                    if (webApp?.initData) {
                        checkUser();
                    }
                }
            }
        }

        // PROD

    }, [webApp?.initData]);

    const getDeviceType = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return "mobile";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "mobile";
        }
        return "desktop";
    };


    const checkUser = async () => {

        localStorage.removeItem('offlien_panny_is_showed');
        // localStorage.removeItem('startSlider');

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();


        try {
            const response = await fetch(`${DOMAIN}/api/v1/login/`, {
                method: 'GET',
                headers: {
                    "X-UserId": init_data
                }
            });

            const statusCode = response.status;

            if (response.ok) {

                const data = await response.json();

                if (statusCode === 200 || statusCode === 201) {


                    const interval = setInterval(() => {
                        sendLogInDb(init_data)
                    }, 1000*60*2 ); // Интервал в миллисекундах (здесь 1000 = 1 секунда)
                    // }, 1000*60*2); // Интервал в миллисекундах (здесь 1000 = 1 секунда)


                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('coin_balance', JSON.stringify(data?.coin_balance));
                    localStorage.setItem('stamina_min', JSON.stringify(data?.stamina));
                    localStorage.setItem('stamina_max', JSON.stringify(data?.stamina_level?.bonus));
                    localStorage.setItem('stamina_level', JSON.stringify(data?.stamina_level?.num));
                    localStorage.setItem('language', JSON.stringify(data?.lang));

                    logEvent('info', 'Login', {
                        coin_balance: JSON.stringify(data?.coin_balance),
                        stamina_min: JSON.stringify(data?.stamina),
                        stamina_max: JSON.stringify(data?.stamina_level?.bonus),
                        stamina_level: JSON.stringify(data?.stamina_level?.num),
                        language: JSON.stringify(data?.lang),
                        action: 'Login'
                    });


                    setIsLogin(true);

                    localStorage.removeItem('get_reward');

                    const ws_ = new WebSocket(`${WEB_SOCKET_DOMAIN}/api/v1/ws/${init_data}/`);

                    ws_.onopen = () => {
                        logEvent('info', 'Websocket Connected', {
                            action: 'WebSocket'
                        });
                        console.log('Connected to WebSocket');
                    };

                    ws_.onmessage = (event) => {
                        console.log('Received message from WebSocket:', event.data);
                    };

                    ws_.onclose = () => {
                        logEvent('info', 'Websocket OnClose', {
                            action: 'WebSocket'
                        });
                        navigate('/error')
                        console.log('Disconnected from WebSocket');};

                    ws_.onerror = (error) => {
                        logEvent('info', 'Websocket OnError', {
                            action: 'WebSocket'
                        });
                        navigate('/error')
                        console.error('WebSocket error:', error);
                    };

                    setSocket(ws_);

                    console.log('Success:', statusCode,  data);

                    setTimeout(()=>{
                        setLoader(false)
                    }, 1000)

                    // navigate('/airdrop')
                }
            } else {

                if (statusCode === 401) {
                    // alert(401)
                    // navigate('/login')
                } else  if (statusCode === 403) {
                    // alert(403)
                    navigate('/banned')
                }
            }
        } catch (e) {
            navigate('/error')
        }

    };

    // const changeAutoData = () => {
    //
    //     let coin_balance = localStorage.getItem('coin_balance');
    //     let userData = localStorage.getItem('user');
    //
    //     let stamina_min = localStorage.getItem('stamina_min');
    //     let stamina_max = localStorage.getItem('stamina_max');
    //
    //
    //     if (coin_balance && userData) {
    //
    //         coin_balance = JSON.parse(coin_balance)
    //         userData = JSON.parse(userData)
    //         stamina_min = JSON.parse(stamina_min)
    //         stamina_max = JSON.parse(stamina_max)
    //
    //         //  Прибовляем баланс каждую секунду
    //         const intelligence_level =  userData?.intelligence_level?.bonus
    //         const new_blance = (parseFloat(coin_balance) + (parseFloat(intelligence_level) / 3600)).toFixed(0);
    //
    //         localStorage.setItem('coin_balance', new_blance);
    //
    //         // Прибовляем стамину - энергию каждую секунду
    //         let new_stamina = parseFloat(stamina_min) + 3;
    //         new_stamina = new_stamina >= stamina_max ? stamina_max : new_stamina;
    //         localStorage.setItem('stamina_min', JSON.stringify(new_stamina) );
    //
    //         if (socket && socket.readyState === WebSocket.OPEN) {
    //
    //             console.log(coin_balance, 'coin_balance' );
    //             console.log(userData, 'userData' );
    //             console.log(stamina_min, 'stamina_min' );
    //             console.log(stamina_max, 'stamina_max' );
    //             console.log(intelligence_level, 'intelligence_level' );
    //             console.log(new_blance, 'new_blance' );
    //             console.log(new_stamina, 'new_stamina' );
    //
    //
    //             // logEvent('info', 'Websocket send data every second', {
    //             //     coin_balance: coin_balance,
    //             //     stamina_min: JSON.stringify(data?.stamina),
    //             //     stamina_max: JSON.stringify(data?.stamina_level?.bonus),
    //             //     stamina_level: JSON.stringify(data?.stamina_level?.num),
    //             //     language: JSON.stringify(data?.lang),
    //             //     action: 'Login'
    //             // });
    //
    //             const data = {
    //                 balance: new_blance,
    //                 stamina: new_stamina,
    //             };
    //             console.log('SENDED', new_blance)
    //             socket.send(JSON.stringify(data));
    //         }
    //     }
    // }


    const changeAutoData = () => {

        let coin_balance = localStorage.getItem('coin_balance');
        let userData = localStorage.getItem('user');

        let stamina_min = localStorage.getItem('stamina_min');
        let stamina_max = localStorage.getItem('stamina_max');

        if (coin_balance && userData) {

            coin_balance = JSON.parse(coin_balance);
            userData = JSON.parse(userData);
            stamina_min = JSON.parse(stamina_min);
            stamina_max = JSON.parse(stamina_max);

            // Прибовляем баланс каждую секунду
            const intelligence_level = userData?.intelligence_level?.bonus || 0;

            // Проверка и преобразование входных данных в числа
            let parsed_coin_balance = parseFloat(coin_balance);
            let parsed_intelligence_level = parseFloat(intelligence_level);

            // Прибовляем стамину - энергию каждую секунду
            let parsed_stamina_min = parseFloat(stamina_min);
            let parsed_stamina_max = parseFloat(stamina_max);

            let stamina_minus = localStorage.getItem('stamina_minus');
            let coin_plus = localStorage.getItem('coin_plus');
            let coin_minus = localStorage.getItem('coin_minus');

            if (stamina_minus || coin_plus || coin_minus) {

                if (stamina_minus) {
                    stamina_minus = parseFloat(stamina_minus);
                    parsed_stamina_min = parsed_stamina_min - stamina_minus < 0 ? 0 : parsed_stamina_min - stamina_minus;
                    localStorage.removeItem('stamina_minus');
                }

                if (coin_plus){
                    coin_plus = parseFloat(coin_plus);
                    parsed_coin_balance = parsed_coin_balance + coin_plus;
                    localStorage.removeItem('coin_plus');
                }

                if (coin_minus){
                    coin_minus = parseFloat(coin_minus);
                    parsed_coin_balance = parsed_coin_balance - coin_minus;
                    localStorage.removeItem('coin_minus');
                }

            }


            if (isNaN(parsed_coin_balance) || isNaN(parsed_intelligence_level)) {
                // console.error('Invalid coin_balance or intelligence_level');
                return;
            }

            const new_balance = (parsed_coin_balance + (parsed_intelligence_level / 7200)).toFixed(0);

            // if (parsed_coin_balance > new_balance) {
                localStorage.setItem('coin_balance', new_balance);
            // }


            if (isNaN(parsed_stamina_min) || isNaN(parsed_stamina_max)) {
                return;
            }

            let new_stamina = parsed_stamina_min + 1.5;
            new_stamina = new_stamina >= parsed_stamina_max ? parsed_stamina_max : new_stamina;
            localStorage.setItem('stamina_min', JSON.stringify(new_stamina));

            if (socket && socket.readyState === WebSocket.OPEN) {

                // console.log(parsed_coin_balance, 'coin_balance');
                // console.log(userData, 'userData');
                // console.log(parsed_stamina_min, 'stamina_min');
                // console.log(parsed_stamina_max, 'stamina_max');
                // console.log(parsed_intelligence_level, 'intelligence_level');

                console.log(new_balance, 'new_balance-');
                console.log(new_stamina, 'new_stamina-');


                logEvent('info', 'Send data in websocket every second', {
                    parsed_coin_balance: parsed_coin_balance,
                    userData: userData,
                    parsed_stamina_min: parsed_stamina_min,
                    parsed_stamina_max: parsed_stamina_max,
                    parsed_intelligence_level: parsed_intelligence_level,
                    new_balance_w: new_balance,
                    new_stamina_w: new_stamina,
                    action: 'Login'
                });

                const data = {
                    balance: new_balance,
                    stamina: new_stamina,
                };

                console.log('SENDED', new_balance);

                socket.send(JSON.stringify(data));
            }
        }
    };


    useEffect(() => {
        const interval = setInterval(() => {
            if(isLogin) {
                changeAutoData()
            }
        }, 500); // Интервал в миллисекундах (здесь 1000 = 1 секунда)
        return () => clearInterval(interval);
    }, [isLogin]);


    if (loader) {
        return (
            <div className={'loader-wrapper'}>

                <div className={'loader-top'}>
                    <p className={'loader-main-title'}>CATSCOIN</p>
                    <div className={'loader-main-subtitle-title-wrapper'}>
                        <img
                            className={'loader-img-catston'}
                            src={CatstonImg}
                        />
                        <p className={'loader-main-subtitle-title'}>TOKEN</p>
                    </div>
                    <img
                        className={'loader-img'}
                        src={CatsImg}
                    />
                </div>
                <div className={'loader-bottom-black'}>

                    <div style={{position:'absolute',margin: '0 auto',left: 0,right: 0, top: 72}}>
                        <Loader  type="spinner-cub" bgColor={'#f67b1a'} color={'white'} title={""} size={50}/>
                    </div>
                    <p className={'loader-bottom-text1'}>
                        will be launched
                    </p>

                    <p className={'loader-bottom-text2'}>
                        ON TON
                    </p>

                    <p className={'loader-bottom-text3'}>
                        Stay tunned <br/>
                        More info in official channels
                    </p>

                    <div className={'loader-bottom-soc-icons'}>
                        <img
                            className={'loader-img-soc'}
                            src={Tgicon}
                        />
                        <img

                            className={'loader-img-soc'}
                            src={YtIcon}
                        />
                        <img
                            className={'loader-img-soc'}
                            src={XIcon}
                            style={{width:34}}
                        />
                    </div>

                </div>
            </div>
        )
    }

    return (
        <TonConnectUIProvider manifestUrl="https://app.catscointon.io/tonconnect-manifest.json">
            <Routes>
                <>
                    <Route path="/" element={<Farm />} />
                    <Route path="/skills" element={<Skills />} />
                    <Route path="/daily-reward" element={<DailyReward />} />
                    <Route path="/refferals" element={<Refferals />} />
                    <Route path="/tasks" element={<Tasks />} />
                    <Route path="/airdrop" element={<Airdrop />} />
                    <Route path="/login" element={<Login setIsLogin={setIsLogin} />} />
                    <Route path="/banned" element={<Banned />} />
                    <Route path="/error" element={<Error />} />
                    <Route path="/qr" element={<QrPage />} />

                    <Route path="/location" element={<Location />} />
                    <Route path="/shopping-mall" element={<ShoppingMall />} />
                    <Route path="/city" element={<City />} />
                    <Route path="/birzha" element={<Birzha />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </>

            </Routes>
        </TonConnectUIProvider>
    );
}

export default App;
