import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/farm.css'
import Footer from "../../components/Footer";
import calendar from '../../assets/images/calendar2.png'
import purpleShadow from '../../assets/images/purpleShadow.png'
import clickerWrapper from '../../assets/images/clickerWrapper.png'
import AnimatedNumber from "../../components/AnimatedNumber";
import Header from "../../components/Header";
import {CloseIcon, EnergyIcon2, IntelektIcon, LapkaIcon, MonetaIcon} from "../../components/icons";
import {useLanguage} from "../../Context/LanguageContext";

function QuestionModal({isActive, onClose, onCloseWithRedirect, userData, payModalType}) {

    const { user } = useTelegram();
    const { t, language } = useLanguage();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const getHeight = () => {

        if (payModalType == 'user') {
            return 400
        } else if (payModalType == 'stamina') {
            return 370
        } else if (payModalType == 'intelekt') {
            return 340
        } else if (payModalType == 'atack') {
            return 410
        }
        console.log('click')

    }

    const RenderIcon = () => {

        if (payModalType == 'user') {
            return (
                <div className={'pay-modal-icon-wrapper'} >
                    <img
                        style={{borderRadius: 10}}
                        className="pay-modal-icon-cat"
                        src={userData?.cat_level?.avatar}
                    />
                </div>
            )
        } else if (payModalType == 'stamina') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <EnergyIcon2 width={90} height={90}/>
                </div>
            )
        } else if (payModalType == 'intelekt') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <IntelektIcon width={90} height={90} style={{marginRight:5}}/>
                </div>

            )
        } else if (payModalType == 'atack') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <LapkaIcon width={90} height={90} style={{marginRight:5}}/>
                </div>

            )
        }

    }

    const RenderInfo = () => {

        if (payModalType == 'user') {
            return (
                <>
                    <h1 className={'question-title'}> {t('crypto_broker')}</h1>

                    <h1 className={'question-title'} style={{fontSize:13}}>
                        {userData?.cat_level?.num} {t('level_uppercase')} / {userData?.cat_level?.league[`name_${language}`] || ''}
                    </h1>

                    <p className={'question-sibtitle'}>
                        {t('user_desc')}
                    </p>
                </>
            )
        } else if (payModalType == 'stamina') {
            return (
                <>
                    <h1 className={'question-title'}>{t('energy')} / {userData?.stamina_level?.num} {t('level_lowercase')}</h1>
                    {/*<p className={'question-title-level'}></p>*/}
                    <p className={'question-sibtitle'}>
                        {t('energy_desc')}
                    </p>
                </>

            )
        } else if (payModalType == 'intelekt') {
            return (
                <>
                    <h1 className={'question-title'}>{t('intelligent')} / {userData?.intelligence_level?.num} {t('level_lowercase')}</h1>
                    {/*<p className={'question-title-level'}></p>*/}
                    <p className={'question-sibtitle'} style={{maxWidth: 400, margin: '0 auto', marginBottom:10}}>
                        {t('intelligent_desc')}
                    </p>
                    <p className={'question-sibtitle'} style={{maxWidth: 400, margin: '0 auto'}}>
                        ({userData?.intelligence_level?.bonus} {t('intelligent2')} = {userData?.intelligence_level?.bonus} {t('monet_per_hour')}).
                    </p>
                </>

            )
        } else if (payModalType == 'atack') {
            return (
                <>
                    <h1 className={'question-title'}>{t('lapka')}</h1>
                    <h1 className={'question-title'}>{t('energy')} / {userData?.attack_level?.num} {t('level_lowercase')}</h1>
                    {/*<p className={'question-title-level'}></p>*/}
                    <p className={'question-sibtitle'}>
                        {t('lapka_desc')}
                    </p>
                </>
            )
        }

    }


    return (
        <div className={`question-reward-wrapper ${isActive ? 'open' : ''}`}>


            <div className={'question-reward'} >
                <img
                    className="questionpurpleShadow"
                    src={purpleShadow}
                />
                <div onClick={() => {
                    onClose()
                }} className={'close-icon'}>
                    <CloseIcon/>
                </div>

                <RenderIcon/>
                <RenderInfo/>

                <div style={{flex: 1, padding: '0 25', display: 'flex'}}>
                    <div
                        onClick={() => {
                            onCloseWithRedirect();
                        }}
                        className={'task-wrapper-right2'}
                    >
                        <p className={'task-text12'}>{t('upgrade')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuestionModal;

