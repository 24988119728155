import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/farm.css'
import Footer from "../../components/Footer";
import calendar from '../../assets/images/calendar2.png'
import purpleShadow from '../../assets/images/purpleShadow.png'
import clickerWrapper from '../../assets/images/clickerWrapper.png'
import AnimatedNumber from "../../components/AnimatedNumber";
import Header from "../../components/Header";
import {
    CloseIcon,
    EnergyIcon2, InstaIcon,
    IntelektIcon,
    LapkaIcon,
    TgIcon, TikTokIcon,
    XIcon,
    YoutubeIcon
} from "../../components/icons";
import MonetaIcon from '../../assets/icons/monet.svg'


import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../Context/LanguageContext";
import {stringToBase64} from "../../utils/format";

function SubscribeModal({isActive, onClose, onCheckClose, newUserData, modalType, coinBalance, subscribeData, updateSocNetworks}) {

    const { user, triggerVibration, webApp } = useTelegram();
    const { t, language } = useLanguage();

    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const navigate = useNavigate();

    useEffect(() => {
        console.log(user, 'user')
        console.log(subscribeData, 'subscribeDatasss')
    }, [subscribeData]);

    const [showAnimation, setShowAnimation] = useState(true);
    const [userData, setUserData] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(()=>{
        setShowAnimation(false);
        setUserData(newUserData);
    }, [isActive])


    const RenderIcon = () => {

        if (modalType == 'x') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <XIcon fill={'white'} style={{width: 200, height: 60, fill: 'white'}}/>
                </div>
        )
        } else if (modalType == 'tg') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                   <TgIcon fill={'white'} style={{width: 200, height: 60, fill: 'white'}}/>
               </div>
            )
        } else if (modalType == 'yt') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                   <YoutubeIcon fill={'white'} style={{width: 200, height: 60, fill: 'white'}}/>
                </div>

            )
        } else if (modalType == 'tk') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <TikTokIcon fill={'white'} style={{width: 200, height: 60, fill: 'white'}}/>
                </div>

            )
        } else if (modalType == 'inst') {
            return (
                <div className={'pay-modal-icon-wrapper'}>
                    <InstaIcon fill={'white'} style={{width: 200, height: 60, fill: 'white'}}/>
                </div>

            )
        }

    }

    const RenderDescription = () => {

        if (modalType == 'x') {
            return (
                <p style={{fontSize: 20, maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                    {t('join_our_channel_at_twiter')}
                </p>
            )
        } else if (modalType == 'tg') {
            return (
                <p style={{fontSize: 20, maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                    {t('join_our_channel_at_telegram')}
                </p>
            )
        } else if (modalType == 'yt') {
            return (
                <p style={{fontSize: 20, maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                    {t('join_our_channel_at_youtube')}
                </p>
            )
        } else if (modalType == 'tk') {
            return (
                <p style={{fontSize: 20, maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                    {t('join_our_channel_at_tiktok')}
                </p>
            )
        } else if (modalType == 'inst') {
            return (
                <p style={{fontSize: 20, maxWidth: 350, margin: '20px auto'}} className={'question-sibtitle'}>
                    {t('join_our_channel_at_instagram')}
                </p>
            )
        }

    }


    const checkSubscribed = async (socNetwork) => {

        let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

        const response = await fetch(`${DOMAIN}/api/v1/check-subscription/${modalType}`, {
            method: 'GET',
            headers: {
                "X-UserId": init_data
            }
        });

        const statusCode = response.status;

        if (response.ok) {

            const data = await response.json();

            if (statusCode === 200 || statusCode === 201) {

                if (data?.result == 2) {

                    // let coin_balance = localStorage.getItem('coin_balance');
                    // let new_blance = parseFloat(coin_balance) + parseFloat(socNetwork?.bonus);
                    // localStorage.setItem('coin_balance', JSON.stringify(new_blance));

                    localStorage.setItem('coin_plus', socNetwork?.bonus);

                    // let  coin_plus = localStorage.getItem('coin_plus');
                    // if (coin_plus) {
                    //     coin_plus = parseFloat(coin_plus) + parseFloat(attack_level);
                    //     localStorage.setItem('coin_plus', JSON.stringify(coin_plus));
                    // } else {
                    //     localStorage.setItem('coin_plus', attack_level);
                    // }

                    // console.log(coin_balance, new_blance);
                    // console.log('data', data);
                    onCheckClose();
                }

            }

        }

    }

    const handleSubscribe = async (socNetwork) => {
        console.log('handleSubscribe', socNetwork)

        if (socNetwork?.is_subscriber) {

            window.open(socNetwork?.url, '_blank');

        } else {
            let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

            const response = await fetch(socNetwork?.link, {
                method: 'GET',
                headers: {
                    "X-UserId": `${init_data}`
                }
            });

            const statusCode = response.status;

            if (response.ok) {

                const data = await response.json();

                if (statusCode === 200 || statusCode === 201) {

                    if (data?.link) {
                        updateSocNetworks();
                        window.open(data?.link, '_blank');
                    }
                    console.log('Success:', statusCode,  data);

                }
            }


        }
    }

    const RenderPrice = () => {

        const socNetwork = subscribeData.find(item => item.type === modalType);

        // console.log(socNetwork, 'socNetworksocNetwork')
        if (modalType == 'x') {

            return (
                <>
                    <div
                        style={{width: 200, margin: '30px auto'}}
                        onClick={() => {
                            triggerVibration('heavy');
                            handleSubscribe(socNetwork)
                        }}
                        className={'task-wrapper-right2'}
                    >
                        <p className={'task-text12'}>
                            {/*Подписаться*/}
                            {t('subscribe')}
                        </p>
                    </div>

                    <div className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 5, width: 24}}
                        />
                        + {socNetwork?.bonus}
                    </div>

                    {socNetwork?.is_subscriber != 2 &&

                        <div style={{flex: 1, padding: '0 25'}}>
                            <div
                                onClick={() => {

                                    if (socNetwork?.is_subscriber == 1) {
                                        triggerVibration('heavy');
                                        checkSubscribed(socNetwork)
                                    }

                                }}
                                className={'task-wrapper-right2'}
                                style={{opacity: socNetwork?.is_subscriber == 0 ? 0.5 : 1}}
                            >

                                <p className={'task-text12'}>
                                    {t('check')}
                                </p>
                            </div>
                        </div>
                    }

                </>
            )
        } else if (modalType == 'tg') {

            return (
                <>
                    <div
                        style={{width: 200, margin: '30px auto'}}
                        onClick={() => {
                            triggerVibration('heavy');
                            handleSubscribe(socNetwork)
                        }}
                        className={'task-wrapper-right2'}

                    >

                        <p className={'task-text12'}>
                             {t('subscribe')}
                        </p>
                    </div>

                    <div style={{width: 200, margin: '0 auto'}} className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 5, width: 24}}
                        />
                        + {socNetwork?.bonus}
                    </div>

                    {socNetwork?.is_subscriber != 2 &&
                        <div style={{flex: 1, padding: '0 25'}}>
                            <div
                                onClick={() => {
                                    if (socNetwork?.is_subscriber == 1) {
                                        triggerVibration('heavy');
                                        checkSubscribed(socNetwork)

                                    }
                                }}
                                className={'task-wrapper-right2'}
                                style={{opacity: socNetwork?.is_subscriber == 0 ? 0.5 : 1}}

                            >
                                <p className={'task-text12'}>
                                     {t('check')}
                                </p>
                            </div>
                        </div>
                    }


                </>
            )

        }

        else if (modalType == 'yt') {

            return (
                <>

                    <div
                        style={{width: 200, margin: '30px auto'}}
                        onClick={() => {
                            triggerVibration('heavy');
                            handleSubscribe(socNetwork)
                        }}
                        className={'task-wrapper-right2'}
                    >
                        <p className={'task-text12'}>
                             {t('subscribe')}
                        </p>
                    </div>

                    <div style={{width: 200, margin: '0 auto'}} className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 5, width: 24}}
                        />
                        + {socNetwork?.bonus}
                    </div>

                    {socNetwork?.is_subscriber != 2 &&

                        <div style={{flex: 1, padding: '0 25'}}>
                            <div
                                onClick={() => {
                                    if (socNetwork?.is_subscriber == 1) {
                                        triggerVibration('heavy');
                                        checkSubscribed(socNetwork);
                                    }
                                }}
                                className={'task-wrapper-right2'}
                                style={{opacity: socNetwork?.is_subscriber == 0 ? 0.5 : 1}}

                            >
                                <p className={'task-text12'}>
                                     {t('check')}
                                </p>
                            </div>
                        </div>
                    }


                </>
            )
        }
        else if (modalType == 'tk') {

            return (
                <>

                    <div
                        style={{width: 200, margin: '30px auto'}}
                        onClick={() => {
                            triggerVibration('heavy');
                            handleSubscribe(socNetwork)
                        }}
                        className={'task-wrapper-right2'}
                    >
                        <p className={'task-text12'}>
                            {t('subscribe')}
                        </p>
                    </div>

                    <div style={{width: 200, margin: '0 auto'}} className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 5, width: 24}}
                        />
                        + {socNetwork?.bonus}
                    </div>

                    {socNetwork?.is_subscriber != 2 &&

                        <div style={{flex: 1, padding: '0 25'}}>
                            <div
                                onClick={() => {
                                    if (socNetwork?.is_subscriber == 1) {
                                        triggerVibration('heavy');
                                        checkSubscribed(socNetwork);
                                    }
                                }}
                                className={'task-wrapper-right2'}
                                style={{opacity: socNetwork?.is_subscriber == 0 ? 0.5 : 1}}

                            >
                                <p className={'task-text12'}>
                                    {t('check')}
                                </p>
                            </div>
                        </div>
                    }


                </>
            )
        }
        else if (modalType == 'inst') {

            return (
                <>

                    <div
                        style={{width: 200, margin: '30px auto'}}
                        onClick={() => {
                            triggerVibration('heavy');
                            handleSubscribe(socNetwork)
                        }}
                        className={'task-wrapper-right2'}
                    >
                        <p className={'task-text12'}>
                            {t('subscribe')}
                        </p>
                    </div>

                    <div style={{width: 200, margin: '0 auto'}} className={'render-price-wrapper'}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={MonetaIcon}
                            alt="arrow icon"
                            style={{marginRight: 5, width: 24}}
                        />
                        + {socNetwork?.bonus}
                    </div>

                    {socNetwork?.is_subscriber != 2 &&

                        <div style={{flex: 1, padding: '0 25'}}>
                            <div
                                onClick={() => {
                                    if (socNetwork?.is_subscriber == 1) {
                                        triggerVibration('heavy');
                                        checkSubscribed(socNetwork);
                                    }
                                }}
                                className={'task-wrapper-right2'}
                                style={{opacity: socNetwork?.is_subscriber == 0 ? 0.5 : 1}}

                            >
                                <p className={'task-text12'}>
                                    {t('check')}
                                </p>
                            </div>
                        </div>
                    }


                </>
            )
        }

    }

    const checkCurrentSocNetwork = () => {
        return  subscribeData.find(item => item.type === modalType);

    }

    return (
        <div className={`question-reward-wrapper subscribe-modal-wrapper ${isActive ? 'open' : ''}`}>


            <div
                className={`question-reward ${checkCurrentSocNetwork()?.is_subscriber == 2 && 'is_subscriber_height'}`}>
                <img
                    className="questionpurpleShadow"
                    src={purpleShadow}
                />
                <div
                    onClick={() => {
                        onClose()
                    }}
                    className={'close-icon'}
                >
                    <CloseIcon/>
                </div>


                <RenderIcon/>
                <RenderDescription/>
                <RenderPrice/>
                {/*<h1 className={'question-title'}>Ежедневная награда</h1>*/}

                {/*<span>{coinBalance}</span>*/}
            </div>
        </div>
    );
}

export default SubscribeModal;

