import React, { useState, useEffect } from 'react';

const TimerComponent = ({sendTimerData, refreshRefferalsList}) => {
    const [secondsLeft, setSecondsLeft] = useState(null); // начинаем с null для понимания неинициализированного состояния

    useEffect(() => {
        // Проверяем наличие сохранённого времени и восстанавливаем таймер при монтировании
        const checkAndStartTimer = () => {
            const startTime = localStorage.getItem('startTime');
            if (startTime) {
                const startTimeDate = new Date(parseInt(startTime));
                const currentTime = new Date();
                const differenceInSeconds = Math.floor((currentTime - startTimeDate) / 1000);

                if (differenceInSeconds < 60) {
                    setSecondsLeft(60 - differenceInSeconds);
                    return true;
                } else {
                    localStorage.removeItem('startTime'); // Удаляем старое время, если оно истекло
                }
            }
            return false;
        };

        if (checkAndStartTimer()) {
            const timer = setInterval(() => {
                setSecondsLeft((prevSeconds) => {
                    if (prevSeconds >= 1) {
                        return prevSeconds - 1;
                    } else {
                        clearInterval(timer);
                        localStorage.removeItem('startTime');
                        return 0;
                    }
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, []);

    const startTimer = () => {
        refreshRefferalsList();

        const currentTime = new Date();
        localStorage.setItem('startTime', currentTime.getTime().toString());
        setSecondsLeft(60);
        const timer = setInterval(() => {
            setSecondsLeft((prevSeconds) => {

                sendTimerData(prevSeconds)

                if (prevSeconds > 1) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(timer);
                    localStorage.removeItem('startTime');
                    return 0;
                }
            });

        }, 1000);
    };

    return (

        <button className={'reff-timer'} onClick={startTimer} disabled={secondsLeft > 0}>
            {secondsLeft == 0 ? '' : secondsLeft}
        </button>
    );
};

export default TimerComponent;
