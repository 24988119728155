import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../Context/TelegramContext';
import '../../../assets/css/banned.css'
import MonetaIcon from '../../../assets/icons/monet.svg'
import {useLanguage} from "../../../Context/LanguageContext";

function Error() {
    const { user } = useTelegram();
    const { t, language } = useLanguage();

    useEffect(() => {
        console.log(user, 'user')
    }, []);


    return (
        <>
            <div className={'wrapper'} style={{
                background: '#1C1F24',
                padding: 24,
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center',
                alignItems:'center',
                height:'100vh'
            }}>

                <h1 className={'title'}>
                    {t('socket_error')}
                </h1>
                <img
                    className="nav__icon"
                    width="100"
                    height="100"
                    src={MonetaIcon}
                    alt="arrow icon"
                    style={{margin: '30px auto'}}
                />
                {/*<div className={'task-wrapper-right'}>*/}
                {/*    <p className={'task-text1'}>Написать в поддержку</p>*/}
                {/*</div>*/}

            </div>

        </>

    );
}

export default Error;

