import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/refferals.css'
import Footer from "../../components/Footer";
import gift from '../../assets/images/gift.png'
import img1 from '../../assets/images/img1.png'
import Header from "../../components/Header";
import clickerWrapper from "../../assets/images/clickerWrapper.png";
import {CopyIcon, CopyWrapperIcon, DotIcon, RefreshIcon} from "../../components/icons";
import MonetaIcon from '../../assets/icons/monet.svg'

import {useClipboard} from "../../components/use-clipboard.ts";
import ReffTimer from "../../components/ReffTimer";
import {useLanguage} from "../../Context/LanguageContext";
import {stringToBase64} from "../../utils/format";
import Loader from "react-js-loader";

function Farm() {
    const { user, webApp } = useTelegram();
    const { t, language } = useLanguage();
    const DOMAIN = process.env.REACT_APP_DOMAIN;

    const [refferals, setRefferals] = useState([]);
    const [refferalsLink, setRefferalsLink] = useState('');
    const [userData, setUserData] = useState(null);
    const [isAnimating, setIsAnimating] = React.useState(false);
    const [refPage, setRefPage] = React.useState(1);
    const [hasNextpage, setHasNextpage] = React.useState(true);
    const [loader, setLoader] = React.useState(true);
    const [moreBonuses, setMoreBonuses] = useState([]);

    const { handleCopy, isCopied } = useClipboard(
        refferalsLink,
        {
            timeout: 1500,
        }
    );


    useEffect(() => {
        setRefferalsLink(`https://t.me/Catscoin_bot?start=RefId_${user?.id ? `${user?.id}` : `844536969`}`)
        handleGetLocalData();
    }, [user]);


    const handleGetLocalData = () => {
        const data = localStorage.getItem('user');
        setUserData(JSON.parse(data));
        setRefferals(JSON.parse(data)?.referred_users)

        setTimeout(()=>{
            setLoader(false)
        }, 500)
    }

    const refreshRefferalsList = async () => {
        handleGetMoreFriends(true)
    }

    const handleGetMoreFriends = async (refresh=false) => {
        let newRefPage;
        if(refresh) {
            setRefPage(1);
            newRefPage = 1; // Подготовка к запросу следующей страницы
        } else {
            newRefPage = refPage + 1; // Подготовка к запросу следующей страницы
        }

        try {
            let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

            const response = await fetch(`${DOMAIN}/api/v1/me/referred-users/?page=${newRefPage}`, {
                method: 'GET',
                headers: {
                    "X-UserId": `${init_data}` // Используем ID пользователя для заголовка
                }
            });

            const data = await response.json(); // Ожидаем и преобразуем ответ в JSON

            if (response.ok) {
                // Обновление состояния с новыми данными рефералов
                if (refresh) {
                    setRefferals(data.results);
                } else {
                    setRefferals(prevRefferals => [...prevRefferals, ...data.results]);
                }

                setRefPage(newRefPage); // Обновление номера текущей страницы
                setHasNextpage(data.has_next_page); // Обновление состояния наличия следующей страницы
            } else {
                console.error('Ошибка при получении данных:', data);
                // Обработка ошибок доступа
                if (response.status === 403) {
                    console.log('Доступ запрещен');
                } else if (response.status === 401) {
                    console.log('Необходима авторизация');
                }
            }
        } catch (error) {
            console.error('Ошибка при запросе:', error);
        }
    }

    const handleShowMoreBonuses = async () => {


        try {
            let init_data = webApp?.initData && webApp?.initData != '' ? stringToBase64(webApp?.initData) : stringToBase64();

            const response = await fetch(`${DOMAIN}/api/v1/add-bonus/`, {
                method: 'GET',
                headers: {
                    "X-UserId": `${init_data}` // Используем ID пользователя для заголовка
                }
            });

            const data = await response.json(); // Ожидаем и преобразуем ответ в JSON

            if (response.ok) {

                console.log(data, 'handleShowMoreBonuses')
                setMoreBonuses(data);

            } else {
                console.error('Ошибка при получении данных:', data);
                // Обработка ошибок доступа
                if (response.status === 403) {
                    console.log('Доступ запрещен');
                } else if (response.status === 401) {
                    console.log('Необходима авторизация');
                }
            }

        } catch (error) {
            console.error('Ошибка при запросе:', error);
        }
    }

    function formatNumber(num) {
        if (num < 1000) {
            return num.toString();
        }

        const units = ["K", "M", "B", "T"];
        const order = Math.floor(Math.log10(num) / 3);
        const unitname = units[order - 1];
        const scaled = num / Math.pow(1000, order);

        // Округление до двух знаков после запятой
        const rounded = Math.floor(scaled * 100) / 100;

        return `${rounded.toFixed(1).replace('.', '.')}${unitname}`;
    }



    return (
        <>
            {loader &&
                <div className={'loader-main-wrapper'}>
                    {/*<Loader type="spinner-cub" bgColor={'#f67b1a'} color={'white'} title={""} size={60}/>*/}
                    <div className="loader"></div>

                    {/*Loading*/}
                </div>
            }

            <div className={'wrapper'} style={{
                background: '#1C1F24',
                padding: 24,
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "column",
                paddingBottom: 140
            }}>

                <h1 className={`title ${isAnimating ? '' : ''}`}>
                    {/*Приглашайте друзьей*/}
                    {t('invite_your_friends')}
                </h1>
                <h2 className={`sub-title ${isAnimating ? '' : ''}`}>
                    {/*Вы и ваш друг получите бонусы*/}
                    {t('you_and_your_friends')}
                </h2>

                <div className={`task-wrapper task-wrapper1 ${isAnimating ? '' : ''}`}>

                    <img className={'gift'} src={gift}/>

                    <div className={'task-wrapper-right-ref'}>
                        <p className={'task-text1-ref'}>
                            {/*Пригласить друга*/}
                            {t('invite_friends')}
                        </p>

                        <p className={'task-text2-ref'} style={{display: "flex", alignItems:'center'}}>
                            <img
                                className="nav__icon"
                                width="24"
                                height="24"
                                src={MonetaIcon}
                                alt="arrow icon"
                                style={{marginRight: 7, width: 13}}
                            />
                            +5,000 {t('you_and_your_friends2')}
                        </p>
                    </div>

                </div>

                <div className={`task-wrapper task-wrapper2 ${isAnimating ? '' : ''}`}>

                    <img className={'gift'} src={gift}/>

                    <div className={'task-wrapper-right-ref'}>
                        <p className={'task-text1-ref'}>{t('invite_premium')}</p>
                        <p className={'task-text2-ref'} style={{display: "flex", alignItems:'center'}}>
                            <img
                                className="nav__icon"
                                width="24"
                                height="24"
                                src={MonetaIcon}
                                alt="arrow icon"
                                style={{marginRight: 7, width: 13}}
                            />
                            +25,000 {t('you_and_your_friends2')}
                        </p>
                    </div>

                </div>


                {moreBonuses.length > 0 ?

                    <>
                        <div className={'more-bonus-wrapper'}>

                            <div className={'more-bonus-wrapper-top'}>
                                <p className={'more-bonus-wrapper-top-title'} style={{fontSize:20, fontWeight: 'bold'}}>
                                    {/*Бонус за повышение уровня*/}
                                    {t('bonuses_for_up_level')}
                                </p>
                            </div>

                            <div className={'more-bonus-title-wrapper'}>
                                <div className={'more-bonus-title-wrapper-item'}>{t('level_uppercase')}</div>
                                <div className={'more-bonus-title-wrapper-item'}> {t('for_friend')}</div>
                                <div className={'more-bonus-title-wrapper-item'}>Premium</div>
                            </div>

                            <div className={'refferals-wrapper'}>

                                {moreBonuses.map((item, index) => {
                                    return (
                                        <div key={index} className={'refferals-item'}>

                                            <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>

                                                <div className={'more-bonus-item-level-text'}>
                                                    #{item?.level?.num ? item?.level?.num : ''}
                                                </div>
                                                <img
                                                    className={'ref-image'}
                                                    src={item?.level?.avatar}
                                                    style={{
                                                        width: 40,
                                                        height: 'unset',
                                                        objectFit: 'contain',
                                                        marginRight: 0
                                                    }}
                                                />
                                            </div>

                                            <div style={{flex: 1, display: 'flex', alignItems: 'center'}}
                                                 className={'refferals-item-center'}>

                                                <div style={{fontSize: 13, alignItems: 'center', color: 'white'}}>

                                                    <div style={{display: "flex", alignItems: 'center'}}>
                                                        <img
                                                            className="nav__icon"
                                                            width="24"
                                                            height="24"
                                                            src={MonetaIcon}
                                                            alt="arrow icon"
                                                            style={{marginRight:8}}
                                                        />
                                                        +{item?.bonus ? formatNumber(item?.bonus) : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{flex: 1}} className={'refferals-item-right'}>
                                                <img
                                                    className="nav__icon"
                                                    width="24"
                                                    height="24"
                                                    src={MonetaIcon}
                                                    alt="arrow icon"
                                                    style={{marginRight:8}}

                                                />
                                                +{item?.prem_bonus ? formatNumber(item?.prem_bonus) : ''}
                                            </div>
                                        </div>
                                    )
                                })}


                            </div>


                        </div>

                    </>
                    :
                    <button
                        className={'show-more-refferals'}
                        style={{marginTop: 10}}
                        onClick={() => {
                            handleShowMoreBonuses()
                        }}
                    >
                        {t('more_bonuses')}
                    </button>

                }


                <div className={'more-bonus-wrapper'}>

                    <div className={'more-bonus-wrapper-top'}>
                        <p className={'more-bonus-wrapper-top-title'}>
                            {t('your_refferals')} ({userData?.referreds_num ? userData?.referreds_num : '0'})
                        </p>
                        <div style={{position: 'relative'}} onClick={refreshRefferalsList}>
                            <RefreshIcon/>
                            <ReffTimer
                                refreshRefferalsList={refreshRefferalsList}
                                sendTimerData={(secondsLeft) => {
                                    console.log(secondsLeft, 'secondsLeft')
                                }}
                                start={true}
                            />
                        </div>
                    </div>

                    {refferals.length > 0 ?

                        <div className={'refferals-wrapper'}>

                            {refferals.map((item, index) => {
                                return (
                                    <div className={'refferals-item'}>

                                        <img className={'ref-image'} src={item?.cat_avatar}/>

                                        <div className={'refferals-item-center'}>
                                            <div className={'ref-name'} style={{color: 'white'}}>
                                                {item?.first_name ? item?.first_name : ''}
                                            </div>
                                            <div style={{fontSize: 13, alignItems: 'center', color: 'white'}}>

                                                {userData?.cat_level?.league[`name_${userData?.lang}`] || ''} / {item?.cat_level_num} {t('level_lowercase')}

                                                <div style={{display: "flex", alignItems: 'center', marginTop:10}}>
                                                    <img
                                                        className="nav__icon"
                                                        width="20"
                                                        height="20"
                                                        src={MonetaIcon}
                                                        alt="arrow icon"
                                                        style={{marginRight:8}}
                                                    />
                                                    {item?.coin_balance ? formatNumber(item?.coin_balance) : '0K'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'refferals-item-right'}>
                                            <img
                                                className="nav__icon"
                                                width="20"
                                                height="20"
                                                src={MonetaIcon}
                                                alt="arrow icon"
                                                style={{marginRight:8}}
                                            />
                                            +{item?.sum_referrer_bonus ? formatNumber(item?.sum_referrer_bonus) : '0К'}
                                        </div>
                                    </div>
                                )
                            })}

                            {hasNextpage &&
                                <button
                                    className={'show-more-refferals'}
                                    onClick={() => {
                                        handleGetMoreFriends()
                                    }}
                                >
                                    {/*Показать еще*/}
                                    {t('show_more')}
                                </button>
                            }


                        </div>
                        :
                        <div className={'empty-refferals-wrapper'}>

                            <div className={'empty-refferals-wrapper-text1'}>
                                {/*Вы ещё никого не пригласили*/}
                                {t('you_havent_invited_anyone')}
                            </div>
                            <div className={'empty-refferals-wrapper-text2'}>(^_^)</div>
                        </div>

                    }

                </div>

                <div className={`btns-wrapper ${isAnimating ? '' : ''}`}>

                    <button
                        onClick={async ()=>{

                            const userId = window.Telegram.WebApp.initDataUnsafe.user?.id || '844536969';

                            const botUrl = `https://t.me/Catscoin_bot?start=RefId_${userId}`;

                            const text = `${t('invite_text1')}\n💰${t('invite_text2')}\n💰${t('invite_text3')}`;
                            const imageUrl = 'https://www.cityguide-dubai.com/fileadmin/_processed_/3/3/csm_img-worlds-of-adventures-teaser_40e4184da1.jpg';

                            const message = `${text}`;
                            const encodedMessage = encodeURIComponent(message);

                            const link = document.createElement('a');
                            link.href = `https://t.me/share/url?url=${botUrl}&text=${encodedMessage}`;
                            link.target = '_blank';
                            link.style.display = 'block';

                            const image = document.createElement('img');
                            image.src = imageUrl;
                            image.alt = 'Telegram Bot';
                            image.style.cursor = 'pointer';

                            link.appendChild(image);
                            document.body.appendChild(link);

                            link.click();
                            document.body.removeChild(link);

                        }}
                        // onClick={handleSendMessage}
                        className={`invite-friends`}
                    >
                        {/*Пригласить друга*/}
                        {t('invite_friends')}
                    </button>

                    <button
                        onClick={handleCopy}
                        className={`copy-link `}
                    >
                        {isCopied &&
                            <div style={{position: 'absolute', top: -71, right: 9,}}>
                            <span
                                style={{
                                    color: 'white',
                                    position: 'absolute',
                                    zIndex: 99999,
                                    top: 33,
                                    fontSize: 11,
                                    right: 0,
                                    left:0
                                }}
                            >
                                {t('copied')}
                            </span>
                                <CopyWrapperIcon/>
                            </div>

                        }

                        <CopyIcon/>
                    </button>
                </div>

            </div>

            <Footer page={'refferals'}/>
        </>

    );
}

export default Farm;

// Аналогично для остальных страниц (Referrals.js, Landing.js, Zadaniya.js)
