import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../Context/TelegramContext';
import '../../assets/css/farm.css'
import Footer from "../../components/Footer";
import calendar from '../../assets/images/calendar2.png'
import purpleShadow from '../../assets/images/purpleShadow.png'
import clickerWrapper from '../../assets/images/clickerWrapper.png'
import AnimatedNumber from "../../components/AnimatedNumber";
import Header from "../../components/Header";
import {CloseIcon, EnergyIcon2, IntelektIcon, LapkaIcon} from "../../components/icons";
import MonetaIcon from '../../assets/icons/monet.svg'
import {useLanguage} from "../../Context/LanguageContext";

function SubscribeModal({isActive, onClose, onPayClose, newUserData, payModalType, coinBalance}) {
    const { user, triggerVibration } = useTelegram();
    const DOMAIN = process.env.REACT_APP_DOMAIN;
    const { t, language } = useLanguage();

    useEffect(() => {
        console.log(user, 'user')
    }, []);

    const [showAnimation, setShowAnimation] = useState(true);
    const [userData, setUserData] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    useEffect(()=>{
        setShowAnimation(false);
        setUserData(newUserData);
    }, [isActive])


    const RenderIcon = () => {

        return (
            <div className={'pay-modal-icon-wrapper'}>
                <img
                    className="pay-modal-icon-cat"
                    src={newUserData?.cat_level?.avatar}
                />
            </div>
        )

    }

    const RenderDescription = () => {

        return (
            <p className={'question-sibtitle'}>
                {/*Биржа начала работать для вас*/}
                {t('the_exchange_has_started_working_for_you')}
            </p>
        )

    }


    const RenderPrice = () => {

        let balance_ = newUserData?.offline_income;

        return (
            <>
                <div className={'render-price-wrapper'}>
                    <img
                        className="nav__icon"
                        width="24"
                        height="24"
                        src={MonetaIcon}
                        alt="arrow icon"
                        style={{marginRight:10}}
                    />
                    {balance_}
                </div>

                <div style={{flex: 1, padding: '0 25'}}>
                    <div
                        style={{}}
                        onClick={() => {

                            triggerVibration('heavy');
                            setShowAnimation(true);

                            onClose();
                        }}
                        className={'task-wrapper-right2'}
                    >

                        <p className={'task-text12'}>

                            <p className={'task-text12'}>
                                {showAnimation ?
                                    <>
                                        <div className="dots-container">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {t('thankyou')}
                                        {/*, {newUserData?.cat_level[`name_${userData?.lang}`] || ''}*/}
                                    </>
                                }
                            </p>
                        </p>
                    </div>
                </div>
            </>
        )

    }

    return (
        <div className={`question-reward-wrapper ofline-panny-wrapper  ${isActive ? 'open' : ''}`}>


            <div className={'question-reward'}>
                <img
                    className="questionpurpleShadow"
                    src={purpleShadow}
                />
                <div
                    onClick={() => {
                        onClose()
                    }}
                    className={'close-icon'}
                >
                    <CloseIcon/>
                </div>

                <RenderIcon/>
                <RenderDescription/>

                {userData?.cat_level &&
                    <RenderPrice/>
                }

            </div>
        </div>
    );
}

export default SubscribeModal;

